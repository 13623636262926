import { Divider } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from 'axios';
import React, {
  useEffect, useState
} from 'react';
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import cocolife from "./assets/cocolife-horizontal.png";
import APIErrorModal from "./components/APIErrorModal";
import useScript from "./components/UseScript";
import APILoadingOverlay from "./components/APILoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import { setFirebaseUID, setProviderEmail, setProvider, trackOldUserID, trackOldUserData, updatePage1, updatePage2 } from "./features/Registration/RegistrationSlice";
import AppleButton from "./components/AppleButton";
import DarkModeSwitch from "./components/DarkModeSwitch";
import FacebookButton from "./components/FacebookButton";
import GoogleButton from "./components/GoogleButton";
import { updateIdleState } from "./features/IdleControl/IdleControlSlice";
import { updateLogoutState } from "./features/LogoutControl/LogoutControlSlice";
import { updateLoadingState } from "./features/LoadingControl/LoadingControlSlice";
import { updatePolicyNumber, updatePolicyDetails } from "./features/Theming/PolicyNumberSlice";
import { auth, firestoreDB } from "./Firebase/firebase";
import { collection, addDoc, getDocs, setDoc, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { useAuth } from "./FirebaseAuth";
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { useFormik, useField, useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import moment from "moment-timezone";
import Finance from 'tvm-financejs';
import CryptoJS from 'crypto-js'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function Login() {
  const navigation = useNavigate();
  let dispatch = useDispatch();

  const finance = new Finance();

  const { firebaseLogout, firebaseSignup, firebaseLogin, getCurrentFirebaseUser, firebaseUpdatePassword } = useAuth()

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const IDLE_STATE = useSelector((state) => state.idleState.value);
  const LOGOUT_STATE = useSelector((state) => state.logoutState.value);

  const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);
  const [oldUserID, setOldUserID] = useState(false);
  const [oldUserData, setOldUserData] = useState({});
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('');
  const [diffProvider, setDiffProvider] = useState('');
  const [firebaseError, setFirebaseError] = useState('');
  const [oldUserDeactivatedUserAccount, setOldUserDeactivatedUserAccount] = useState({});
  const [geoData, setGeoData] = useState({})

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [modalPrivacyPolicy, setModalPrivacyPolicy] = useState(true);
  const [modalUserIdleLogout, setModalUserIdleLogout] = useState(false);
  const [modalErrorLogin, setModalErrorLogin] = useState(false);
  const [modalExistingAccountLogin, setModalExistingAccountLogin] = useState(false);
  const [modalForgotUsernameInputEmail, setModalForgotUsernameInputEmail] = useState(false);
  const [modalEmailDoesNotExist, setModalEmailDoesNotExist] = useState(false);
  const [modalSuccessfulEmailSend, setModalSuccessfulEmailSend] = useState(false);

  const [modalForgotPasswordInputEmail, setModalForgotPasswordInputEmail] = useState(false);
  const [modalOTPVerification, setModalOTPVerification] = useState(false);
  const [modalErrorOTP, setModalErrorOTP] = useState(false);
  const [modalExpiredMobileOTP, setModalExpiredMobileOTP] = useState(false);
  const [modalExpiredEmailOTP, setModalExpiredEmailOTP] = useState(false);
  const [modalSuccessfullChangePass, setModalSuccessfullChangePass] = useState(false);
  const [modalServerError, setModalServerError] = useState(false);
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const [modalFirebaseSyncError, setModalFirebaseSyncError] = useState(false);
  const [modalRegisteredWithDiffProvider, setModalRegisteredWithDiffProvider] = useState(false);
  const [modalNoPasswordForSocialiteAccount, setModalNoPasswordForSocialiteAccount] = useState(false);
  const [modalFirebaseError, setModalFirebaseError] = useState(false);
  const [modalOldUserAccountHasBeenDeactivated, setModalOldUserAccountHasBeenDeactivated] = useState(false);

  const [timerOnState, putTimerOnState] = useState();
  const [timerOnState2, putTimerOnState2] = useState();
  const [seconds, setSeconds] = useState(30);
  const [seconds2, setSeconds2] = useState(30);
  const [timerIsActive, setTimerIsActive] = useState(false);
  const [timerIsActive2, setTimerIsActive2] = useState(false);
  const [smsOTPexpirationDate, setsmsOTPexpirationDate] = useState(moment().add(300, 'seconds'));
  const [emailOTPexpirationDate, setEmailOTPexpirationDate] = useState(moment().add(300, 'seconds'));

  const [mfaTableID, setmfaTableID] = useState(null);

  const [mobileNumber, setMobileNumber] = useState('')

  const [regiSchemaOTP, setRegiSchemaOTP] = useState(
    Yup.object().shape({
      emailOtp: Yup.string()
        .required('Email OTP is required'),
    })
  );

  const [otpSelected, setotpSelected] = useState('email');

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("~~~~")
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    console.log("this is user", getCurrentFirebaseUser());
    console.log("this is user email", getCurrentFirebaseUser()?.email);
    console.log("~~~~")

    dispatch(updateLoadingState(isLoading));
    let testDate = "01 01 2000"
    console.log("is date valid?", moment(testDate).isValid() ? moment(testDate).format("MMMM DD, YYYY") : null)
  }, [isLoading])

  useEffect(() => {
    async function init() {
      const geoData = await axios.get('https://geolocation-db.com/json/')
      console.log("geoData", geoData)
      setGeoData(geoData);

      // moment.tz.setDefault("America/Los_Angeles");
      // let stringTmp = "abcdefg"
      // let truncatedPayment = stringTmp.slice(0, 3);
      // console.log("truncatedPayment", truncatedPayment)
    }
    init()
  }, [])

  useEffect(() => {
    async function init() {
      // moment.tz.setDefault("Asia/Manila");
      // moment.tz.setDefault("America/Los_Angeles");
      // let stringTmp = "abcdefg"
      // let truncatedPayment = stringTmp.slice(0, 3);
      // console.log("truncatedPayment", truncatedPayment)
    }
    init()
  }, [])

  useEffect(() => {
    if (otpSelected === 'sms') {
      setRegiSchemaOTP(
        Yup.object().shape({
          otp: Yup.string()
            .required('Mobile OTP is required'),
        })
      )
    } else {
      setRegiSchemaOTP(
        Yup.object().shape({
          emailOtp: Yup.string()
            .required('Email OTP is required'),
        })
      )
    }
  }, [otpSelected])

  useEffect(() => {
    if (timerIsActive) {
      // console.log('timerIsActive', timerIsActive)
      const timer = setInterval(() => {
        // console.log('seconds', seconds)
        setSeconds((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
      }, 1000);

      if (seconds <= 0) {
        console.log('timer done')
        setSeconds(30)
        setTimerIsActive(false)
      }

      putTimerOnState(timer);

      return () => {
        clearInterval(timer);
      };
    }
  }, [seconds, timerIsActive])

  useEffect(() => {
    if (timerIsActive2) {
      // console.log('timerIsActive2', timerIsActive2)
      const timer2 = setInterval(() => {
        // console.log('seconds', seconds)
        setSeconds2((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
      }, 1000);

      if (seconds2 <= 0) {
        console.log('timer2 done')
        setSeconds2(30)
        setTimerIsActive2(false)
      }

      putTimerOnState2(timer2);

      return () => {
        clearInterval(timer2);
      };
    }
  }, [seconds2, timerIsActive2])

  useEffect(() => {
    async function init() {
      // API TESTING
      if (process.env.REACT_APP_ENV == 'local' || process.env.REACT_APP_ENV == 'test-server') {
        try {
          console.log("testpost test 1");
          await axios.post(process.env.REACT_APP_API_URL + '/api/testpost')
            .then((res) => {
              console.log("testpost res: ", res);
            })
        } catch (error) {
          console.log("testpost error", error);
        }
      }
    }

    init()
  }, [])

  useEffect(() => {
    async function init() {
      // console.log("react app env", process.env.REACT_APP_ENV)
    }

    init()
  }, [])

  useEffect(() => {
    if (IDLE_STATE === 'logged-out') {
      setModalUserIdleLogout(true);
      setModalPrivacyPolicy(false);
      dispatch(updateIdleState(''));
      dispatch(updateLogoutState(false));
    }
  }, [IDLE_STATE])

  useEffect(() => {
    if (LOGOUT_STATE === true) {
      setModalPrivacyPolicy(false);
      dispatch(updateLogoutState(false));
    }
  }, [LOGOUT_STATE])

  useEffect(() => {
    async function init() {
      try {
        await axios.get(process.env.REACT_APP_API_URL + '/api').then((res) => {
          console.log("express res: ", res);
        })
      } catch (error) {
        console.log("express error", error);
      }

      if (process.env.REACT_APP_ENV == 'local' || process.env.REACT_APP_ENV == 'test-server') {
        try {
          await axios.get('http://localhost:5001/api').then((res) => {
            console.log("localhost express res: ", res);
          })
        } catch (error) {
          console.log("axios error", error);
        }
      }

      dispatch(trackOldUserID(''));
      dispatch(trackOldUserData({}));
      dispatch(setProvider(''));
      dispatch(setProviderEmail(''));
      dispatch(setFirebaseUID(''));
      dispatch(updatePage1({}))
      dispatch(updatePage2({}))
    }
    init()
  }, [])

  const sendMFAOTP = async (type = 'sms') => {
    console.log('called sendMFAOTP');
    try {
      console.log("createMFAOTP axios call")
      await axios.post(process.env.REACT_APP_API_URL + '/api/createMFAOTP', {
        type,
        target: mobileNumber,
      }).then(async (res) => {
        console.log("sendMFAOTP res", res)
        let mfaTableID = res.data.data.mfaTableID;
        console.log("mfaTableID", mfaTableID)
        setmfaTableID(mfaTableID);

        // await sendEmailOTP()
      })
    } catch (error) {
      console.log("axios error", error);
    }
  }

  const verifyMFAOTP = async (type = 'sms') => {
    console.log("formikOTPVerification.values.otp != ''", formikOTPVerification.values.otp != '')
    if (formikOTPVerification.values.otp.trim() != '' && formikOTPVerification.values.otp != null) {
      if (!moment().isSameOrAfter(smsOTPexpirationDate)) {
        try {
          await axios.post(process.env.REACT_APP_API_URL + '/api/verifyMFAOTP', {
            mfaTableID: mfaTableID,
            otpCode: formikOTPVerification.values.otp,
          }).then(async (res) => {
            console.log("res", res)
            console.log("res.data.result", res.data.result)

            if (res.data.result === 'success') {
              console.log("verifyMFAOTP success")
              setIsLoading(false);
              setModalOTPVerification(false)
              setModalChangePassword(true)
              return { success: true }
            } else {
              // sendMFAOTP('sms')
              setModalErrorOTP(true);
              setIsLoading(false)
              return { success: false }
            }
          })
        } catch (error) {
          console.log("axios error", error);
          setIsLoading(false);
          return { success: false }
          // return {
          //   error: "There was na issue with the MFA. Please contact our customer support."
          // }
        }
      } else {
        setIsLoading(false)
        console.log("moment().isSameOrAfter(smsOTPexpirationDate)", moment().isSameOrAfter(smsOTPexpirationDate))
        setModalExpiredMobileOTP(true)
      }
    } else {
      setIsLoading(false)
      console.log("mobileotp is blank")
      formikOTPVerification.setErrors({ otp: 'Mobile OTP is required' });
    }
  }

  const sendEmailOTP = async (type = 'sms') => {
    console.log('called sendEmailOTP');
    try {
      console.log("sendMail axios call")
      console.log("email", formikForgotPassword.values.email)
      await axios.post(process.env.REACT_APP_API_URL + '/api/otpGenerator', {
        email: formikForgotPassword.values.email,
        type: 'forgot-password',
      }).then(async (res) => {
        console.log("sendMail res", res)
      })
    } catch (error) {
      console.log("axios error", error);
    }
  }

  const verifyMailOTP = async (type = 'sms') => {
    console.log("formikOTPVerification.values.emailOtp", formikOTPVerification.values.emailOtp != '')
    if (formikOTPVerification.values.emailOtp.trim() != '' && formikOTPVerification.values.emailOtp != null) {
      console.log("formikOTPVerification.values.emailOtp.split('').length", formikOTPVerification.values.emailOtp.split('').length)
      if (formikOTPVerification.values.emailOtp.split('').length == 6) {
        if (!moment().isSameOrAfter(emailOTPexpirationDate)) {
          try {
            console.log("sendMail axios call")
            await axios.post(process.env.REACT_APP_API_URL + '/api/otpValidate', {
              token: formikOTPVerification.values.emailOtp,
              email: formikForgotPassword.values.email,
              type: 'forgot-password',
            }).then(async (res) => {
              console.log("sendMail res", res)

              if (res.data.data.Success) {
                console.log("verifyMailOTP success")
                setIsLoading(false);
                setModalOTPVerification(false)
                setModalChangePassword(true)
              } else if (res.data.data.Error == 'Expired OTP') {
                setModalExpiredEmailOTP(true);
                setIsLoading(false)
              } else {
                setModalErrorOTP(true);
                setIsLoading(false)
              }

            })
          } catch (error) {
            console.log("axios error", error);
            setIsLoading(false);
            return { success: false }
            // return {
            //   error: "There was na issue with the MFA. Please contact our customer support."
            // }
          }
        } else {
          console.log("moment().isSameOrAfter(emailOTPexpirationDate)", moment().isSameOrAfter(emailOTPexpirationDate))
          setModalExpiredEmailOTP(true)
          setIsLoading(false)
        }
      } else {
        setModalErrorOTP(true);
        setIsLoading(false)
      }

    } else {
      setIsLoading(false)
      console.log("emailOtp is blank")
      formikOTPVerification.setErrors({ emailOtp: 'Email OTP is required' });
    }
  }

  const regiSchemaForgotUsername = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
  });

  const formikForgotUsername = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema: regiSchemaForgotUsername,
    onSubmit: async values => {
      // console.log({ values })
      console.log("formikForgotUsername.values", formikForgotUsername.values)
      // setFormValues({ ...values })

      await axios.post(process.env.REACT_APP_API_URL + '/api/sendUsernameToEmail', {
        email: values.email,
      }).then(async (sendUsernameToEmailRes) => {
        console.log("sendUsernameToEmailRes", sendUsernameToEmailRes)
        if (sendUsernameToEmailRes.Success === false) {
          console.log("sendUsernameToEmailRes fail")

          setModalEmailDoesNotExist(true)
          setIsLoading(false);
        } else {
          console.log("sendUsernameToEmailRes success")
          setModalSuccessfulEmailSend(true)
          setIsLoading(false);
        }
      }).catch((error) => {
        console.log('changePasswordByUsernamePassword error', error)
        setIsLoading(false);
      })
      // save record
      // saveToBackend({ ...values, birthday: dateThing })
    }
  })

  const handleMyPolicyLogin = async () => {
    let error = false;
    console.log("setIsLoading true")
    setIsLoading(true);

    let currentAPICall = 'getOldUserAccountDetails';

    if (!error) {
      try {
        let oldUserAccountReqData = {
          username: username,
          password: password
        }

        await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByUsernamePassword', {
          username: username,
          password: password
        }).then(async (getUserByUsernamePasswordRes) => {
          console.log("getUserByUsernamePasswordRes", getUserByUsernamePasswordRes)

          if (getUserByUsernamePasswordRes.data.user === false) {
            await axios.post(process.env.REACT_APP_API_URL + '/api/getOldUserAccountDetails',
              oldUserAccountReqData
            ).then(async (getOldUserAccountDetailsRes) => {
              console.log("getOldUserAccountDetails getOldUserAccountDetailsRes.data.Data", getOldUserAccountDetailsRes.data.Data);

              if (getOldUserAccountDetailsRes === 'null') {
                setIsLoading(false);
                setapiName('getOldUserAccountDetails')
                setapiResponse({
                  Code: '408',
                  Message: "API request timeout."
                })
                setAPIErrorModalOpen(true)
                console.log('issue', 'getOldUserAccountDetails getOldUserAccountDetailsRes is null')
                return
              }

              currentAPICall = 'Database connection';
              if (getOldUserAccountDetailsRes.data.Success) {

                // IF NEW USER HAS AN OLD ACCOUNT
                if (getOldUserAccountDetailsRes.data.Data.RegistrationDate != null &&
                  getOldUserAccountDetailsRes.data.Data.EmailAddress != null) {
                  await axios.post(process.env.REACT_APP_API_URL + '/api/getOldUserAccountByUsernamePassword', {
                    username: username,
                    password: password
                  }).then(async (getOldUserAccountByUsernamePasswordRes) => {
                    console.log("getOldUserAccountByUsernamePasswordRes", getOldUserAccountByUsernamePasswordRes)
                    if (getOldUserAccountByUsernamePasswordRes.data.user != null && getOldUserAccountByUsernamePasswordRes.data.user != false) {
                      console.log("getOldUserAccountByUsernamePassword User not found")
                      if (getOldUserAccountByUsernamePasswordRes.data.user.recordset[0].status === 'Deactivated') {
                        // IF OLD USER ACCOUNT HAS ALREADY BEEN DEACTIVATED
                        setOldUserDeactivatedUserAccount(getOldUserAccountByUsernamePasswordRes.data.user.recordset[0])
                        setModalOldUserAccountHasBeenDeactivated(true)
                        console.log("setIsLoading false")
                        setIsLoading(false);
                        return
                      }
                    }

                    // IF OLD USER ACCOUNT HAS NOT BEEN SAVED
                    console.log("getOldUserAccountByUsernamePassword User not found")

                    if (getOldUserAccountByUsernamePasswordRes.data.user === false) {
                      // IF THE OLD USER ACCOUNT HAS NOT BEEN SAVED IN DB
                      await axios.post(process.env.REACT_APP_API_URL + '/api/saveOldUserAccount', {
                        username: username,
                        password: password,
                        email: getOldUserAccountDetailsRes.data.Data.EmailAddress,
                        mobileNumber: getOldUserAccountDetailsRes.data.Data.MobileNumber,
                        registrationDate: getOldUserAccountDetailsRes.data.Data.RegistrationDate,
                        secretAnswer: getOldUserAccountDetailsRes.data.Data.SecretAnswer,
                        secretQuestion: getOldUserAccountDetailsRes.data.Data.SecretQuestion,
                        status: getOldUserAccountDetailsRes.data.Data.Status,
                      }).then(async (saveOldUserAccountRes) => {
                        console.log("saveOldUserAccountRes.data.Success", saveOldUserAccountRes.data.Success)

                        if (saveOldUserAccountRes.data.data.Success) {

                          currentAPICall = 'getOldUserAccountByUsernamePassword';
                          await axios.post(process.env.REACT_APP_API_URL + '/api/getOldUserAccountByUsernamePassword', {
                            username: username,
                            password: password,
                          }).then(async (getOldUserAccountRes2) => {
                            if (getOldUserAccountRes2.data.user === false) {
                              //  big error here
                              console.log("User not found")
                              console.log("setIsLoading false")
                              setIsLoading(false);
                              return
                            }
                            console.log("getOldUserAccountRes2", getOldUserAccountRes2)

                            await axios.post(process.env.REACT_APP_API_URL + '/api/saveOldPolicies', {
                              username: username,
                              password: password,
                              oldUserID: getOldUserAccountRes2.data.user.recordset[0].id,
                            }).then(async (saveOldPoliciesRes) => {
                              console.log("saveOldPoliciesRes 1", saveOldPoliciesRes)
                              let oldUserID = getOldUserAccountRes2.data.user.recordset[0].id;
                              console.log("oldUserID", oldUserID)
                              console.log("setIsLoading false")
                              console.log("getOldUserAccountDetailsRes.data.Data.EmailAddress", getOldUserAccountDetailsRes.data.Data.EmailAddress)

                              setOldUserID(oldUserID)
                              setOldUserData({
                                username: username,
                                password: password,
                                email: getOldUserAccountDetailsRes.data.Data.EmailAddress,
                              })
                              setModalExistingAccountLogin(true)
                              setIsLoading(false);
                            }).catch((error) => {
                              console.log("saveOldPolicies error", error)
                              console.log("setIsLoading false")
                              setIsLoading(false);
                              // setModalServerError(true)
                              setOldUserID(oldUserID)
                              setOldUserData({
                                username: username,
                                password: password,
                                email: getOldUserAccountDetailsRes.data.Data.EmailAddress,
                              })
                              setModalExistingAccountLogin(true)
                            })
                          }).catch((error) => {
                            console.log("getOldUserAccountByUsernamePassword error", error)
                            console.log("setIsLoading false")
                            setIsLoading(false);
                            setapiName('getOldUserAccountByUsernamePassword')
                            setapiResponse(getOldUserAccountDetailsRes.data.Response)
                            setAPIErrorModalOpen(true)
                          })

                        } else {
                          console.log("saveOldUserAccountRes error", saveOldUserAccountRes)
                          console.log("setIsLoading false")
                          setIsLoading(false);
                          return false
                        }
                      })
                      // setModalErrorLogin(true)
                      return false
                    } else {
                      // IF THE OLD USER ACCOUNT WAS ALREADY SAVED IN DB
                      currentAPICall = 'getOldUserAccountByUsernamePassword';
                      await axios.post(process.env.REACT_APP_API_URL + '/api/getOldUserAccountByUsernamePassword', {
                        username: username,
                        password: password,
                      }).then(async (getOldUserAccountRes2) => {
                        if (getOldUserAccountRes2.data.user === false) {
                          //  big error here
                          console.log("User not found")
                          console.log("setIsLoading false")
                          setIsLoading(false);
                          return
                        }
                        console.log("getOldUserAccountRes2", getOldUserAccountRes2)

                        await axios.post(process.env.REACT_APP_API_URL + '/api/saveOldPolicies', {
                          username: username,
                          password: password,
                          oldUserID: getOldUserAccountByUsernamePasswordRes.data.user.recordset[0].id,
                        }).then(async (saveOldPoliciesRes) => {
                          console.log("saveOldPoliciesRes 2", saveOldPoliciesRes)
                          let oldUserID = getOldUserAccountByUsernamePasswordRes.data.user.recordset[0].id;
                          console.log("oldUserID", oldUserID)
                          console.log("setIsLoading false")
                          console.log("getOldUserAccountDetailsRes.data.Data.EmailAddress", getOldUserAccountDetailsRes.data.Data.EmailAddress)

                          setOldUserID(oldUserID)
                          setOldUserData({
                            username: username,
                            password: password,
                            email: getOldUserAccountDetailsRes.data.Data.EmailAddress,
                          })
                          setModalExistingAccountLogin(true)
                          setIsLoading(false);
                        }).catch((error) => {
                          console.log("saveOldPolicies error", error)
                          console.log("setIsLoading false")
                          setIsLoading(false);
                          // setModalServerError(true)
                          setOldUserID(oldUserID)
                          setOldUserData({
                            username: username,
                            password: password,
                            email: getOldUserAccountDetailsRes.data.Data.EmailAddress,
                          })
                          setModalExistingAccountLogin(true)
                        })

                        return false
                      }).catch((error) => {
                        console.log("setIsLoading false")
                        setIsLoading(false);
                        setapiName('getOldUserAccountDetails')
                        setapiResponse(getOldUserAccountDetailsRes.data.Response)
                        setAPIErrorModalOpen(true)
                      })
                    }
                  })
                } else {
                  await axios.post(process.env.REACT_APP_API_URL + '/api/getOldUserAccountByUsernamePassword', {
                    username: username,
                    password: password
                  }).then(async (getOldUserAccountByUsernamePasswordRes) => {
                    if (getOldUserAccountByUsernamePasswordRes.data.user === false) {
                      console.log("setIsLoading false")
                      setIsLoading(false);
                      setModalErrorLogin(true)
                      return false
                    } else {
                      // IF API CANNOT FIND OLD USER BUT DB HAS RECORD OF OLD USER
                      setOldUserDeactivatedUserAccount(getOldUserAccountByUsernamePasswordRes.data.user.recordset[0])
                      setModalOldUserAccountHasBeenDeactivated(true)
                      console.log("setIsLoading false")
                      setIsLoading(false);
                    }
                  })

                }
              } else {
                console.log("setIsLoading false")
                setIsLoading(false);

                setapiName('getOldUserAccountDetails')
                setapiResponse(getOldUserAccountDetailsRes.data.Response)
                setAPIErrorModalOpen(true)
                console.log('issue', 'getOldUserAccountDetails res', getOldUserAccountDetailsRes.data.Response)
                return
              }
            })
          } else {
            // IF USER ALREADY HAS AN ACCOUNT                      
            let user = getUserByUsernamePasswordRes.data.user.recordset[0];
            let email = user.email

            // CHECK IF USER HAS FIREBASE ACCOUNT
            firebaseSignup(email, password).then(async (response) => {
              console.log("success creating firebase account")

              // CREATE USER IF USER HAS NO FIREBASE ACCOUNT
              console.log("firebase res", response)

              // SIGN IN WITH THE NEW FIREBASE ACCOUNT
              firebaseLogin(email, password)
                .then(async (response) => {
                  // console.log("successful login to firebase")
                  // console.log("firebase response", response)
                  // console.log("firebase response.uid", response.uid)

                  console.log("user logged in", user)

                  if (user.policy_number == null || user.policy_number == '') {
                    console.log('user.policy_number is null', user.policy_number)
                    // window.location.replace('https://www.cocolife.com/products')
                    await validateNoPolicyUser(user)
                  } else {
                    await axios.post(process.env.REACT_APP_API_URL + '/api/rePopulatePolicies', {
                      username: username,
                      password: password
                    }).then(async (rePopulatePoliciesRes) => {
                      console.log("rePopulatePoliciesRes", rePopulatePoliciesRes)

                      if (rePopulatePoliciesRes.data.APIcall != null && rePopulatePoliciesRes.data.APIcall != '') {
                        setapiName(rePopulatePoliciesRes.data.APIcall)
                        setAPIErrorModalOpen(true)
                        setapiResponse(rePopulatePoliciesRes.data.Response)
                        console.log('issue', rePopulatePoliciesRes.data.APIcall, 'res', rePopulatePoliciesRes.data.Response)
                      } else {
                        console.log("getAllPoliciesByCurrentPolicy req", { policyNumber: user.policy_number, registered: true, })

                        await axios.post(process.env.REACT_APP_API_URL + '/api/getAllPoliciesByCurrentPolicy', {
                          policyNumber: user.policy_number,
                          registered: true,
                        }).then(async (getAllPoliciesByCurrentPolicyRes) => {
                          console.log("Currently selected policy:", getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number)

                          await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
                            policyNumber: getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number,
                          })
                            .then(async (res) => {
                              console.log("getPolicyProfile res.data.Data", res.data.Data);

                              if (res === 'null') {
                                setIsLoading(false)
                                setapiName('getPolicyProfile')
                                setapiResponse({
                                  Code: '408',
                                  Message: "API request timeout."
                                })
                                setAPIErrorModalOpen(true)
                                console.log('issue', 'getPolicyProfile res is null')
                                return
                              } else if (res.data.Success) {
                                setIsLoading(false);
                                // dispatch(updatePolicyNumber(getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number));
                                let isVL = res.data.Data?.ProductType === 'VL'
                                let IsTermPlan = res.data.Data?.IsTermPlan === 'True'

                                let policyDetails = {
                                  policyNumber: getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number,
                                  isVL: isVL,
                                  isTermPlan: IsTermPlan,
                                }
                                dispatch(updatePolicyDetails(policyDetails));

                                axios.post(process.env.REACT_APP_API_URL + '/api/updateLatestUserLogin', { email, geoData: geoData })
                                navigation('/mypolicy');
                              } else {
                                setIsLoading(false)
                                setapiName('getPolicyProfile')
                                setapiResponse(res.data)
                                setAPIErrorModalOpen(true)
                                console.log('issue', 'getPolicyProfile res', res.data.Response)
                                return
                              }
                            })
                        })
                      }
                    }).catch((error) => {
                      if (error.response) {
                        console.error("Error.response:", error.response);
                        setIsLoading(false);
                        setapiName('rePopulatePolicies')
                        setapiResponse({
                          Code: error.response.status,
                          Message: error.response.statusText
                        })
                        setAPIErrorModalOpen(true)
                        console.log('issue', 'rePopulatePolicies getOldUserAccountDetailsRes is null')
                        return
                      } else {
                        setIsLoading(false);
                        setapiName('rePopulatePolicies')
                        setapiResponse({
                          Code: '408',
                          Message: "API request timeout."
                        })
                        setAPIErrorModalOpen(true)
                        console.log('issue', 'rePopulatePolicies getOldUserAccountDetailsRes is null')
                        return
                      }
                    }).finally(() => {
                      console.log("setIsLoading false")
                      setIsLoading(false);
                    })

                  }
                }).catch((error) => {

                  // firebaseSignup(email, password).then(async (response) => {

                  // }).catch((error) => {

                  // })

                  console.log("setIsLoading false")
                  setModalFirebaseSyncError(true);
                  setIsLoading(false);
                  console.error("Firebase Login Error:", error);
                })

            }).catch((error) => {
              // IF USER EXISTING HAS A FIREBASE ACCOUNT
              // console.error("Firebase Signup Error:", error);
              console.error("Firebase Signup Error:", error.message);

              // SIGN IN WITH THE EXISTING FIREBASE ACCOUNT
              firebaseLogin(email, password)
                .then(async (response) => {
                  // console.log("successful login to firebase")
                  // console.log("firebase response", response)
                  // console.log("firebase response.uid", response.uid)

                  console.log("user logged in", user)

                  if (user.policy_number == null || user.policy_number == '') {
                    console.log('user.policy_number is null', user.policy_number)
                    // window.location.replace('https://www.cocolife.com/products')
                    await validateNoPolicyUser(user)
                  } else {
                    await axios.post(process.env.REACT_APP_API_URL + '/api/rePopulatePolicies', {
                      username: username,
                      password: password
                    }).then(async (rePopulatePoliciesRes) => {
                      console.log("rePopulatePoliciesRes", rePopulatePoliciesRes)
                      if (rePopulatePoliciesRes.data.APIcall != null && rePopulatePoliciesRes.data.APIcall != '') {
                        setapiName(rePopulatePoliciesRes.data.APIcall)
                        setAPIErrorModalOpen(true)
                        setapiResponse(rePopulatePoliciesRes.data.Response)
                        console.log('issue', rePopulatePoliciesRes.data.APIcall, 'res', rePopulatePoliciesRes.data.Response)
                      } else {
                        await axios.post(process.env.REACT_APP_API_URL + '/api/getAllPoliciesByCurrentPolicy', {
                          policyNumber: user.policy_number,
                          registered: true,
                        }).then(async (getAllPoliciesByCurrentPolicyRes) => {
                          console.log("Currently selected policy:", getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number)

                          await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
                            policyNumber: getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number,
                          })
                            .then(async (res) => {
                              console.log("getPolicyProfile res.data.Data", res.data.Data);

                              if (res === 'null') {
                                setIsLoading(false)
                                setapiName('getPolicyProfile')
                                setapiResponse({
                                  Code: '408',
                                  Message: "API request timeout."
                                })
                                setAPIErrorModalOpen(true)
                                console.log('issue', 'getPolicyProfile res is null')
                                return
                              } else if (res.data.Success) {
                                setIsLoading(false);
                                // dispatch(updatePolicyNumber(getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number));
                                let isVL = res.data.Data?.ProductType === 'VL'
                                let IsTermPlan = res.data.Data?.IsTermPlan === 'True'

                                let policyDetails = {
                                  policyNumber: getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number,
                                  isVL: isVL,
                                  isTermPlan: IsTermPlan,
                                }
                                dispatch(updatePolicyDetails(policyDetails));

                                axios.post(process.env.REACT_APP_API_URL + '/api/updateLatestUserLogin', { email, geoData: geoData })
                                navigation('/mypolicy');
                              } else {
                                setIsLoading(false)
                                setapiName('getPolicyProfile')
                                setapiResponse(res.data)
                                setAPIErrorModalOpen(true)
                                console.log('issue', 'getPolicyProfile res', res.data.Response)
                                return
                              }
                            })

                        }).catch(async (error) => {
                          await axios.post(process.env.REACT_APP_API_URL + '/api/getAllPoliciesByCurrentPolicy', {
                            policyNumber: user.policy_number,
                            registered: true,
                          }).then(async (getAllPoliciesByCurrentPolicyRes) => {
                            console.log("Currently selected policy:", getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number)

                            await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
                              policyNumber: getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number,
                            })
                              .then(async (res) => {
                                console.log("getPolicyProfile res.data.Data", res.data.Data);

                                if (res === 'null') {
                                  setIsLoading(false)
                                  setapiName('getPolicyProfile')
                                  setapiResponse({
                                    Code: '408',
                                    Message: "API request timeout."
                                  })
                                  setAPIErrorModalOpen(true)
                                  console.log('issue', 'getPolicyProfile res is null')
                                  return
                                } else if (res.data.Success) {
                                  setIsLoading(false);
                                  // dispatch(updatePolicyNumber(getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number));
                                  let isVL = res.data.Data?.ProductType === 'VL'
                                  let IsTermPlan = res.data.Data?.IsTermPlan === 'True'

                                  let policyDetails = {
                                    policyNumber: getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number,
                                    isVL: isVL,
                                    isTermPlan: IsTermPlan,
                                  }
                                  dispatch(updatePolicyDetails(policyDetails));

                                  axios.post(process.env.REACT_APP_API_URL + '/api/updateLatestUserLogin', { email, geoData: geoData })
                                  navigation('/mypolicy');
                                } else {
                                  setIsLoading(false)
                                  setapiName('getPolicyProfile')
                                  setapiResponse(res.data)
                                  setAPIErrorModalOpen(true)
                                  console.log('issue', 'getPolicyProfile res', res.data.Response)
                                  return
                                }
                              })

                          }).catch((error) => {
                            setIsLoading(false);
                            setapiName('getAllPoliciesByCurrentPolicy')
                            setapiResponse({
                              Code: '408',
                              Message: "API request timeout."
                            })
                            setAPIErrorModalOpen(true)
                            console.log('issue getAllPoliciesByCurrentPolicy', error)
                            return
                          })
                        })
                      }

                    }).catch((error) => {
                      console.error("Error.response:", error.response);
                      if (error.response) {
                        console.error("Error.response:", error.response);
                        setIsLoading(false);
                        setapiName('rePopulatePolicies')
                        setapiResponse({
                          Code: error.response.status,
                          Message: error.response.statusText
                        })
                        setAPIErrorModalOpen(true)
                        console.log('issue rePopulatePolicies is null', error)
                        return
                      } else {
                        setIsLoading(false);
                        setapiName('rePopulatePolicies')
                        setapiResponse({
                          Code: '408',
                          Message: "API request timeout."
                        })
                        setAPIErrorModalOpen(true)
                        console.log('issue rePopulatePolicies is null')
                        return
                      }
                    }).finally(() => {
                      console.log("setIsLoading false")
                      setIsLoading(false);
                    })
                  }
                }).catch((error) => {
                  console.log("setIsLoading false")
                  setModalFirebaseSyncError(true);
                  setIsLoading(false);
                  console.error("Firebase Login Error:", error);
                })
            })
          }
        })
      } catch (error) {
        if (error.response) {
          console.error("Error.response:", error.response);
          setIsLoading(false);
          setapiName(currentAPICall)
          setapiResponse({
            Code: error.response.status,
            Message: error.response.statusText
          })
          setAPIErrorModalOpen(true)
          console.log('issue', currentAPICall, 'getOldUserAccountDetailsRes is null')
          return
        } else {
          setIsLoading(false);
          setapiName(currentAPICall)
          setapiResponse({
            Code: '408',
            Message: "API request timeout."
          })
          setAPIErrorModalOpen(true)
          console.log('issue', currentAPICall, 'getOldUserAccountDetailsRes is null')
          return
        }
      }
    }
  }

  const handleMyPolicySocialiteLogin = async (provider) => {
    console.log("setIsLoading true")
    setIsLoading(true);

    var socialiteProvider

    try {
      if (provider === 'google') {
        socialiteProvider = new GoogleAuthProvider();
      } else if (provider === 'facebook') {
        socialiteProvider = new FacebookAuthProvider();
      } else if (provider === 'apple') {
        socialiteProvider = new OAuthProvider('apple.com');
      } else {
        console.log("Socialite Provider:", provider, "does not exist")
        alert("Socialite Provider: " + provider + " does not exist")
        setIsLoading(false);
        return
      }

      auth.signInWithPopup(socialiteProvider)
        .then(async (res) => {
          console.log("firebase login success res.user", res.user)
          console.log("res.user.email", res.user?.email)
          let email = res.user?.email

          if (email == '') {
            email = null;
          }

          await axios.post(process.env.REACT_APP_API_URL + '/api/getUserProviderByEmail', {
            email
          }).then(async (getUserProviderByEmailRes) => {
            console.log("getUserProviderByEmailRes", getUserProviderByEmailRes)
            if (getUserProviderByEmailRes.data.success === false) {
              // If user does not have a DB account
              // Register User with provider as provider and use provider email
              console.log("getUserProviderByEmailRes user does not exist")
              dispatch(setProvider(provider));
              dispatch(setProviderEmail(email));
              dispatch(setFirebaseUID(res.user.uid));

              // auth.currentUser.delete().then((res2) => {
              //   console.log("deleted user", res2)
              // })

              await firebaseLogout()
                .then(async (firebaseResponse) => {
                  console.log("successful logout from firebase")
                  setIsLoading(false);
                  console.log("navigating now to registration")
                  navigation('/registration');

                }).catch((error) => {
                  console.error("unsuccessful logout from firebase:", error);
                  setIsLoading(false);
                  console.log("navigating now to registration")
                  navigation('/registration');
                })

            } else {
              // If user has a DB account
              console.log("getUserProviderByEmailRes user exist")
              if (getUserProviderByEmailRes.data.provider != provider) {
                // If user's account is not from provider
                let diffProviderVerbose = ''

                if (getUserProviderByEmailRes.data.provider === 'emailpassword') {
                  diffProviderVerbose = 'Email and Password'
                } else if (getUserProviderByEmailRes.data.provider === 'apple') {
                  diffProviderVerbose = 'Apple'
                } else if (getUserProviderByEmailRes.data.provider === 'facebook') {
                  diffProviderVerbose = 'Facebook'
                } else if (getUserProviderByEmailRes.data.provider === 'google') {
                  diffProviderVerbose = 'Google'
                }

                setIsLoading(false);
                setDiffProvider(diffProviderVerbose);
                setModalRegisteredWithDiffProvider(true)

                // auth.currentUser.delete().then((res2) => {
                //   console.log("deleted user", res2)
                // })

                firebaseLogout()
                  .then(async (firebaseResponse) => {
                    console.log("successful logout from firebase")

                  }).catch((error) => {
                    console.error("unsuccessful logout from firebase:", error);
                  })

              } else {
                // If user's account is from provider
                await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByEmail', {
                  email,
                }).then(async (getUserByEmailRes) => {
                  console.log("getUserByEmailRes", getUserByEmailRes)
                  let user = getUserByEmailRes.data?.user.recordset[0]

                  if (user.policy_number == null || user.policy_number == '') {
                    console.log('user.policy_number is null', user.policy_number)
                    // window.location.replace('https://www.cocolife.com/products')
                    await validateNoPolicyUser(user)
                  } else {
                    await axios.post(process.env.REACT_APP_API_URL + '/api/rePopulatePolicies', {
                      username: user.username,
                      password: null,
                      provider: provider
                    }).then(async (rePopulatePoliciesRes) => {
                      console.log("rePopulatePoliciesRes", rePopulatePoliciesRes)

                      if (rePopulatePoliciesRes.data.APIcall != null && rePopulatePoliciesRes.data.APIcall != '') {

                        firebaseLogout()
                          .then(async (firebaseResponse) => {
                            console.log("successful logout from firebase")

                          }).catch((error) => {
                            console.error("unsuccessful logout from firebase:", error);
                          })
                        setapiName(rePopulatePoliciesRes.data.APIcall)
                        setAPIErrorModalOpen(true)
                        setapiResponse(rePopulatePoliciesRes.data.Response)
                        console.log('issue', rePopulatePoliciesRes.data.APIcall, 'res', rePopulatePoliciesRes.data.Response)
                      } else {
                        await axios.post(process.env.REACT_APP_API_URL + '/api/getAllPoliciesByCurrentPolicy', {
                          policyNumber: user.policy_number,
                          registered: true,
                        }).then(async (getAllPoliciesByCurrentPolicyRes) => {
                          console.log("Currently selected policy:", getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number)

                          await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
                            policyNumber: getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number,
                          })
                            .then(async (res) => {
                              console.log("getPolicyProfile res.data.Data", res.data.Data);

                              if (res === 'null') {
                                setIsLoading(false)
                                setapiName('getPolicyProfile')
                                setapiResponse({
                                  Code: '408',
                                  Message: "API request timeout."
                                })
                                setAPIErrorModalOpen(true)
                                console.log('issue', 'getPolicyProfile res is null')
                                return
                              } else if (res.data.Success) {
                                setIsLoading(false);
                                // dispatch(updatePolicyNumber(getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number));
                                let isVL = res.data.Data?.ProductType === 'VL'
                                let IsTermPlan = res.data.Data?.IsTermPlan === 'True'

                                let policyDetails = {
                                  policyNumber: getAllPoliciesByCurrentPolicyRes.data.user.recordset[0].policy_number,
                                  isVL: isVL,
                                  isTermPlan: IsTermPlan,
                                }
                                dispatch(updatePolicyDetails(policyDetails));

                                axios.post(process.env.REACT_APP_API_URL + '/api/updateLatestUserLogin', { email, geoData: geoData })
                                navigation('/mypolicy');
                              } else {
                                setIsLoading(false)
                                setapiName('getPolicyProfile')
                                setapiResponse(res.data)
                                setAPIErrorModalOpen(true)
                                console.log('issue', 'getPolicyProfile res', res.data.Response)
                                return
                              }
                            })
                        })
                      }

                    }).catch((error) => {
                      firebaseLogout()
                        .then(async (firebaseResponse) => {
                          console.log("successful logout from firebase")

                        }).catch((error) => {
                          console.error("unsuccessful logout from firebase:", error);
                        })
                      if (error.response) {
                        console.error("Error.response:", error.response);
                        setIsLoading(false);
                        setapiName('rePopulatePolicies')
                        setapiResponse({
                          Code: error.response.status,
                          Message: error.response.statusText
                        })
                        setAPIErrorModalOpen(true)
                        console.log('issue', 'rePopulatePolicies getOldUserAccountDetailsRes is null')
                        return
                      } else {
                        setIsLoading(false);
                        setapiName('rePopulatePolicies')
                        setapiResponse({
                          Code: '408',
                          Message: "API request timeout."
                        })
                        setAPIErrorModalOpen(true)
                        console.log('issue', 'rePopulatePolicies getOldUserAccountDetailsRes is null')
                        return
                      }
                    }).finally(() => {
                      console.log("setIsLoading false")
                      setIsLoading(false);
                    })
                  }

                }).catch((error) => {
                  console.log('getUserByEmail error', error)
                  firebaseLogout()
                    .then(async (firebaseResponse) => {
                      console.log("successful logout from firebase")

                    }).catch((error) => {
                      console.error("unsuccessful logout from firebase:", error);
                    })

                  setIsLoading(false);
                  setapiName('Database connection')
                  setapiResponse({
                    Code: '408',
                    Message: "API request timeout."
                  })
                  setAPIErrorModalOpen(true)
                  console.log('issue', 'Database connection is null')
                  return
                })
              }
            }
          }).catch((error) => {
            console.log('getUserProviderByEmail error', error)

            if (auth.currentUser != null) {
              // auth.currentUser.delete().then((res2) => {
              //   console.log("deleted user", res)
              // })
            }

            firebaseLogout()
              .then(async (firebaseResponse) => {
                console.log("successful logout from firebase")

              }).catch((error) => {
                console.error("unsuccessful logout from firebase:", error);
              })

            setIsLoading(false);
            setapiName('Database connection')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'Database connection is null')
            return
          })


        })
        .catch((err) => {
          setIsLoading(false);
          setFirebaseError(err.message)
          setModalFirebaseError(true)
          console.log("err", err)
        })
    } catch (error) {
      console.log("error", error)
      setIsLoading(false);
    }
  }

  const validateNoPolicyUser = async (user) => {
    console.log("validateUser is called", user);

    return await axios.post(process.env.REACT_APP_API_URL + '/api/validateUser', {
      policyNumber: user?.policy_number,
      firstName: user?.first_name,
      middleName: user?.middle_name,
      lastName: user?.last_name,
      suffix: user?.suffix,
      birthday: user?.birthday,
      mobileNumber: user?.mobile_number,
      emailAddress: user?.email,
    })
      .then(async (res) => {
        console.log("validateUser res.data.Data", res.data.Data);

        if (res === 'null') {
          setIsLoading(false)
          setapiName('validateUser')
          setapiResponse({
            Code: '408',
            Message: "API request timeout."
          })
          setAPIErrorModalOpen(true)
          console.log('issue', 'validateUser res is null')
          return
        } else if (res.data.Success) {
          // dispatch(updatePolicyNumber(user.policy_number));

          if (res.data.Data?.PersonID == 0 && process.env.REACT_APP_ENV != 'local') {
            console.log('issue', 'validateUser res.PersonID is 0')

            firebaseLogout()
              .then(async (firebaseResponse) => {
                console.log("successful logout from firebase")

              }).catch((error) => {
                console.error("unsuccessful logout from firebase:", error);
              })

            console.log('redirecting to coco products')
            window.location.replace('https://www.cocolife.com/products')

            return
          } else if (res.data.Data?.PolicyNumber == null || res.data.Data?.PolicyNumber == '') {
            console.log('issue', 'validateUser res.PolicyNumber is null')

            firebaseLogout()
              .then(async (firebaseResponse) => {
                console.log("successful logout from firebase")

              }).catch((error) => {
                console.error("unsuccessful logout from firebase:", error);
              })

            console.log('redirecting to coco products')
            window.location.replace('https://www.cocolife.com/products')

            return
          } else {
            let policyType = 'trad'
            if (res.data.Data?.PolicyNumber.split("")[0].toLowerCase() === 'v') {
              policyType = 'vl'
            }

            await axios.post(process.env.REACT_APP_API_URL + '/api/add1stUserPolicy', {
              policyNumber: res.data.Data?.PolicyNumber,
              username: user.username,
              password: password,
              userId: user.user_id,
              policyType: policyType,
            })
              .then(async (res) => {
                console.log("add1stUserPolicy res.data", res.data);

                if (res === 'null') {
                  setIsLoading(false)
                  setapiName('add1stUserPolicy')
                  setapiResponse({
                    Code: '408',
                    Message: "API request timeout."
                  })
                  setAPIErrorModalOpen(true)
                  console.log('issue', 'add1stUserPolicy res is null')
                  return
                } else if (res.data.Success) {

                  await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByUsernamePassword', {
                    username: username,
                    password: password
                  })
                    .then(async (res) => {
                      console.log("getUserByUsernamePassword res.data.Data", res.data.Data);

                      if (res === 'null') {
                        setIsLoading(false)
                        setapiName('getUserByUsernamePassword')
                        setapiResponse({
                          Code: '408',
                          Message: "API request timeout."
                        })
                        setAPIErrorModalOpen(true)
                        console.log('issue', 'getUserByUsernamePassword res is null')
                        return
                      } else if (res.data?.user != null && res.data.user != '') {
                        return rePopulateUserPolicies(res.data.user)
                      } else {
                        setIsLoading(false)
                        setapiName('getUserByUsernamePassword')
                        setapiResponse(res.data)
                        setAPIErrorModalOpen(true)
                        console.log('issue', 'getUserByUsernamePassword res', res.data.Response)
                        return
                      }
                    })
                } else {
                  setIsLoading(false)
                  setapiName('add1stUserPolicy')
                  setapiResponse(res.data)
                  setAPIErrorModalOpen(true)
                  console.log('issue', 'add1stUserPolicy res', res.data.Response)
                  return
                }
              })
          }
        } else {
          setIsLoading(false)
          setapiName('validateUser')
          setapiResponse(res.data)
          setAPIErrorModalOpen(true)
          console.log('issue', 'validateUser res', res.data.Response)
          return
        }
      })
  }

  const rePopulateUserPolicies = async (user) => {
    await axios.post(process.env.REACT_APP_API_URL + '/api/rePopulatePolicies', {
      username: user.recordset[0].username,
      password: password,
      provider: user.recordset[0].provider
    }).then(async (rePopulatePoliciesRes) => {
      console.log("rePopulatePoliciesRes", rePopulatePoliciesRes)

      if (rePopulatePoliciesRes.data.APIcall != null && rePopulatePoliciesRes.data.APIcall != '') {

        firebaseLogout()
          .then(async (firebaseResponse) => {
            console.log("successful logout from firebase")

          }).catch((error) => {
            console.error("unsuccessful logout from firebase:", error);
          })
        setapiName(rePopulatePoliciesRes.data.APIcall)
        setAPIErrorModalOpen(true)
        setapiResponse(rePopulatePoliciesRes.data.Response)
        console.log('issue', rePopulatePoliciesRes.data.APIcall, 'res', rePopulatePoliciesRes.data.Response)
      } else {
        await axios.post(process.env.REACT_APP_API_URL + '/api/getAllPoliciesByCurrentPolicy', {
          policyNumber: user.recordset[0].policy_number,
          registered: true,
        }).then(async (getAllPoliciesByCurrentPolicyRes) => {
          console.log("Currently selected policy:", user.recordset[0].policy_number)

          await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
            policyNumber: user.recordset[0].policy_number,
          })
            .then(async (res) => {
              console.log("getPolicyProfile res.data.Data", res.data.Data);

              if (res === 'null') {
                setIsLoading(false)
                setapiName('getPolicyProfile')
                setapiResponse({
                  Code: '408',
                  Message: "API request timeout."
                })
                setAPIErrorModalOpen(true)
                console.log('issue', 'getPolicyProfile res is null')
                return
              } else if (res.data.Success) {
                setIsLoading(false);
                // dispatch(updatePolicyNumber(user.recordset[0].policy_number));
                let isVL = res.data.Data?.ProductType === 'VL'
                let IsTermPlan = res.data.Data?.IsTermPlan === 'True'

                let policyDetails = {
                  policyNumber: user.recordset[0].policy_number,
                  isVL: isVL,
                  isTermPlan: IsTermPlan,
                }
                dispatch(updatePolicyDetails(policyDetails));

                axios.post(process.env.REACT_APP_API_URL + '/api/updateLatestUserLogin', { email: user.recordset[0].email, geoData: geoData })
                navigation('/mypolicy');
              } else {
                setIsLoading(false)
                setapiName('getPolicyProfile')
                setapiResponse(res.data)
                setAPIErrorModalOpen(true)
                console.log('issue', 'getPolicyProfile res', res.data.Response)
                return
              }
            })
        })
      }
    }).catch((error) => {
      firebaseLogout()
        .then(async (firebaseResponse) => {
          console.log("successful logout from firebase")

        }).catch((error) => {
          console.error("unsuccessful logout from firebase:", error);
        })
      if (error.response) {
        console.error("Error.response:", error.response);
        setIsLoading(false);
        setapiName('rePopulatePolicies')
        setapiResponse({
          Code: error.response.status,
          Message: error.response.statusText
        })
        setAPIErrorModalOpen(true)
        console.log('issue', 'rePopulatePolicies getOldUserAccountDetailsRes is null')
        return
      } else {
        setIsLoading(false);
        setapiName('rePopulatePolicies')
        setapiResponse({
          Code: '408',
          Message: "API request timeout."
        })
        setAPIErrorModalOpen(true)
        console.log('issue', 'rePopulatePolicies getOldUserAccountDetailsRes is null')
        return
      }
    }).finally(() => {
      console.log("setIsLoading false")
      setIsLoading(false);
    })
  }

  const regiSchemaForgotPassword = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
  });

  const formikForgotPassword = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema: regiSchemaForgotPassword,
    onSubmit: async values => {
      // console.log({ values })
      console.log("formikForgotUsername.values", formikForgotUsername.values)
      // setFormValues({ ...values })


      await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByEmail', {
        email: values.email,
      }).then(async (getUserByEmailRes) => {
        console.log("getUserByEmailRes", getUserByEmailRes)
        if (getUserByEmailRes.data.user === false) {
          console.log("getUserByEmailRes fail")

          setModalEmailDoesNotExist(true)
          setIsLoading(false);
        } else {

          if (getUserByEmailRes.data.user.recordset[0].provider === 'emailpassword') {
            console.log("getUserByEmailRes success")
            setMobileNumber(getUserByEmailRes.data.user.recordset[0].mobile_number)
            formikOTPVerification.resetForm()
            if (otpSelected === 'sms') {
              sendMFAOTP()
            } else {
              sendEmailOTP()
            }
            setModalForgotPasswordInputEmail(false)
            setModalOTPVerification(true)
            setIsLoading(false);
          } else {
            let diffProviderVerbose = ''
            console.log("getUserByEmailRes.data.user.recordset[0].provider", getUserByEmailRes.data.user.recordset[0].provider)

            if (getUserByEmailRes.data.user.recordset[0].provider === 'emailpassword') {
              diffProviderVerbose = 'Email and Password'
            } else if (getUserByEmailRes.data.user.recordset[0].provider === 'apple') {
              diffProviderVerbose = 'Apple'
            } else if (getUserByEmailRes.data.user.recordset[0].provider === 'facebook') {
              diffProviderVerbose = 'Facebook'
            } else if (getUserByEmailRes.data.user.recordset[0].provider === 'google') {
              diffProviderVerbose = 'Google'
            }

            setIsLoading(false);
            setDiffProvider(diffProviderVerbose);
            setModalNoPasswordForSocialiteAccount(true)
          }

        }
      }).catch((error) => {
        if (error.response) {
          console.error("Error.response:", error.response);
          setIsLoading(false);
          setapiName('getUserByEmail')
          setapiResponse({
            Code: error.response.status,
            Message: error.response.statusText
          })
          setAPIErrorModalOpen(true)
          console.log('issue', 'getUserByEmail is null')
          return
        } else {
          setIsLoading(false);
          setapiName('getUserByEmail')
          setapiResponse({
            Code: '408',
            Message: "API request timeout."
          })
          setAPIErrorModalOpen(true)
          console.log('issue', 'getUserByEmail is null')
          return
        }
      })
      // save record
      // saveToBackend({ ...values, birthday: dateThing })
    }
  })

  const regiSchemaChangePassword = Yup.object().shape({
    newPassword: Yup.string()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .matches(/^(?=.*?[A-Z])/, 'At least one upper case letter is required')
      .matches(/^(?=.*?[a-z])/, 'At least one lower case letter is required')
      .matches(/^(?=.*?[0-9])/, 'At least one digit is required')
      .matches(/^(?=.*?[#?!@$%^&*-])/, 'At least one special character is required')
      .min(8, 'At least 8 characters')
      .max(50, 'Too Long!')
      .required('Required'),
    confirmPassword: Yup.string()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Required'),
  });

  const formikChangePassword = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    enableReinitialize: true,
    validationSchema: regiSchemaChangePassword,
    onSubmit: async values => {
      // console.log("formikChangePassword.values", formikChangePassword.values)
      // setFormValues({ ...values })
      // console.log("username", username)
      // console.log("newPassword", formikChangePassword.values.newPassword)

      // await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByEmail', {
      //   username: username,
      //   password: formikChangePassword.values.newPassword
      // }).then(async (getUserByEmailRes) => {
      //   console.log("getUserByEmailRes", getUserByEmailRes)
      //   if (getUserByEmailRes.data.user == false) {
      //     console.log("getUserByEmailRes fail")
      //     setIsLoading(false);
      //   } else {
      //     console.log("getUserByEmailRes success")
      //     handleChangePassword();
      //   }
      //   setIsLoading(false);
      // }).catch((error) => {
      //   console.log('getUserByUsernamePassword error', error)
      //   setIsLoading(false);
      // })
      handleChangePassword();
    }
  })

  const formikOTPVerification = useFormik({
    initialValues: {
      otp: "",
      emailOtp: "",
    },
    enableReinitialize: true,
    validationSchema: regiSchemaOTP,
    onSubmit: async (values) => {
      console.log("onSubmit called")
      console.log("setIsLoading true")

      console.log("moment().isSameOrAfter(smsOTPexpirationDate)", moment().isSameOrAfter(smsOTPexpirationDate))

      if (otpSelected === 'sms') {
        verifyMFAOTP()
      } else {
        verifyMailOTP()
      }
      // verifyMailOTP()

      // console.log({ values otp != '' || })
    }
  })

  const handleChangePassword = async () => {
    setIsLoading(true);
    await axios.post(process.env.REACT_APP_API_URL + '/api/changePasswordByEmail', {
      email: formikForgotPassword.values.email,
      newPassword: formikChangePassword.values.newPassword,
      emailOtp: formikOTPVerification.values.emailOtp,
    }).then(async (changePasswordByEmailRes) => {
      console.log("changePasswordByEmailRes", changePasswordByEmailRes)
      if (changePasswordByEmailRes.data.Success) {
        console.log("changePasswordByEmailRes success")

        setModalChangePassword(false)
        setModalSuccessfullChangePass(true)
      } else {
        console.log("changePasswordByEmailRes fail")

        formikOTPVerification.resetForm()
        if (otpSelected === 'sms') {
          sendMFAOTP()
        } else {
          sendEmailOTP()
        }
        setModalOTPVerification(true)
        setModalServerError(true)
        setModalChangePassword(false)
      }
    }).catch((error) => {
      console.log('changePasswordByUsernamePassword error', error)
      setIsLoading(false);
    })
  }

  const handleGLOESLogin = () => {
    let error = false;
    if (username != 'Benjamin') {
      error = true;
    }
    if (password != 'B3nj@min') {
      error = true;
    }
    setModalErrorLogin(error)
    // console.log("handleGLOESLogin Click")
    if (!error) {
      navigation('/gloes');
    }
  }

  return (
    <div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalPrivacyPolicy}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalPrivacyPolicy(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalPrivacyPolicy}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Privacy Policy Statement
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Your privacy is important to us. By using this site, you agree to
              the use of cookies and your personal information in accordance
              with our{" "}
              <Link
                href="https://www.cocolife.com/about/privacy-policy/"
                target="_blank"
                sx={{ textDecoration: "none" }}
                color="text.linkActive"
              >
                Privacy Policy
              </Link>
              .
            </Typography>

            <Stack direction="row">
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography
                    color="text.secondary">
                    I AGREE to the Privacy Policy{' '}
                  </Typography>}
                checked={agreedToPrivacyPolicy}
                sx={{ mt: 4 }}
                onClick={() => {
                  setAgreedToPrivacyPolicy(!agreedToPrivacyPolicy)
                }} />
            </Stack>
            <Stack direction="row"
              sx={{ maxWidth: '70%' }}>
              <Typography
                sx={{ textAlign: 'left', marginLeft: 4, fontSize: '14px' }}
                color="text.secondary">
                {/* Please {' '} */}
                <Link
                  href="/privacy-policy"
                  target="_blank"
                  color="text.linkActive"
                  sx={{ textDecoration: "none" }}
                >
                  {/* click here  */}
                  Please view our Data Privacy Policy here &raquo;
                </Link>
                {/* to learn more about our Data Privacy. */}
              </Typography>
            </Stack>

            {agreedToPrivacyPolicy ?
              <Button variant="contained" onClick={() => {
                setModalPrivacyPolicy(false)
              }} sx={{ mt: 4 }}>
                Proceed
              </Button>
              :
              <Button variant="contained" sx={{ mt: 4 }} disabled>
                Proceed
              </Button>}
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalUserIdleLogout}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalUserIdleLogout(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalUserIdleLogout}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Your have been automatically logged out.
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              User has been logged out automatically due to inactivity.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              This is a security measure to prevent any unwanted actions.
            </Typography>


            <Button variant="contained" onClick={() => {
              setModalUserIdleLogout(false)
            }} sx={{ mt: 4 }}>
              Proceed
            </Button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalForgotUsernameInputEmail}
        onClose={() => {
          setModalForgotUsernameInputEmail(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalForgotUsernameInputEmail}>
          <Box sx={style}>

            <FormControl sx={{ display: "flex", lexDirection: "column", alignItems: "center", textAlign: 'center' }}>
              <form onSubmit={formikForgotUsername.handleSubmit} name='formikForgotUsername' key='formikForgotUsername'>

                <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
                  Forgot Username
                </Typography>

                <Divider sx={{ my: 3, width: '100%' }} />

                <Stack sx={{ alignItems: "center" }}>
                  <Typography id="transition-modal-description" variant="body1" color="text.secondary"
                    sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '70%' }}>
                    Please enter your Email Address that you used to register.
                  </Typography>
                  <Typography id="transition-modal-description" variant="body1" color="text.secondary"
                    sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '70%' }}>
                    We will send your username there.
                  </Typography>

                  <TextField
                    name="email"
                    variant="outlined"
                    hiddenLabel
                    value={formikForgotUsername.values.email}
                    placeholder='Email Address'
                    onChange={formikForgotUsername.handleChange}
                    sx={{ mb: 0, minWidth: 80, maxWidth: '100%' }}
                  />
                  {formikForgotUsername.errors.email ? <Typography color="red">{formikForgotUsername.errors.email}</Typography> : null}
                </Stack>

                <Divider sx={{ my: 3, width: '100%' }} />

                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={3}
                  sx={{ mt: 2, width: '100%' }}
                >
                  <Button type="submit" variant="contained" size="large">
                    Submit
                  </Button>
                </Stack>

              </form>
            </FormControl>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalExistingAccountLogin}
        onClose={() => {
          setModalExistingAccountLogin(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalExistingAccountLogin}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Welcome back!
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '80%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              We have detected that you have an existing account.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              color="text.secondary"
            >
              Update your information on our registration page to proceed.
            </Typography>

            <Button variant="contained"
              onClick={() => {
                setModalExistingAccountLogin(false)
                dispatch(trackOldUserID(oldUserID));
                dispatch(trackOldUserData(oldUserData));
                dispatch(setProvider('emailpassword'));
                console.log("oldUserID onClick", oldUserID)

                navigation('/registration');
              }}
              sx={{ mt: 4 }}>
              Register
            </Button>



          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalErrorLogin}
        onClose={() => {
          setModalErrorLogin(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalErrorLogin}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Your username or password is incorrect.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Please enter the correct password.
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalErrorLogin(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalEmailDoesNotExist}
        onClose={() => {
          setModalEmailDoesNotExist(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalEmailDoesNotExist}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              The Email Address you have entered does not have an account.
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalEmailDoesNotExist(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalSuccessfulEmailSend}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalSuccessfulEmailSend(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalSuccessfulEmailSend}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              We have sent your username to your Email Address.
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalSuccessfulEmailSend(false)
                setModalForgotUsernameInputEmail(false)
                formikForgotUsername.values.email = ''
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOTPVerification}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalOTPVerification(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOTPVerification}>
          <Box sx={style}>

            <FormControl sx={{ display: "flex", lexDirection: "column", alignItems: "center", textAlign: 'center' }}>
              <form onSubmit={formikOTPVerification.handleSubmit} name='formikOTPVerification' key='formikOTPVerification'>

                <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
                  Change Password OTP Verification
                </Typography>


                <Divider sx={{ my: 3, width: '100%' }} />

                {otpSelected === 'sms' ?
                  <>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="body1" color="text.secondary"
                        sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Enter the OTP Code that we have sent to your Mobile Number
                      </Typography>

                      <TextField
                        name="otp"
                        variant="outlined"
                        hiddenLabel
                        value={formikOTPVerification.values.otp}
                        placeholder='Mobile OTP Code'
                        onChange={formikOTPVerification.handleChange}
                        sx={{ minWidth: 80, maxWidth: '100%' }}
                      />
                      {formikOTPVerification.errors.otp ? <Typography color="red">{formikOTPVerification.errors.otp}</Typography> : null}
                    </Stack>


                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mt: 1 }}
                    >
                      {timerIsActive ?
                        <Typography variant="subtitle1" color="text.secondary"
                          sx={{ mt: 1, textAlign: 'center' }}>
                          Resend a new OTP code in {seconds} seconds
                        </Typography>
                        :
                        <Button variant="text" size="large"
                          onClick={() => {
                            setSeconds(30);
                            setTimerIsActive(true);
                            setsmsOTPexpirationDate(moment().add(300, 'seconds'));
                            sendMFAOTP();
                          }}>
                          Resend a new OTP Code to mobile
                        </Button>}
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="body1" color="text.secondary"
                        sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Mobile OTP Expiry: {smsOTPexpirationDate.format('MMM DD, YYYY hh:mm:ss a')}
                      </Typography>
                    </Stack>
                  </>
                  :
                  <>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="body1" color="text.secondary"
                        sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Enter the OTP Code that we have sent to your Email Address
                      </Typography>

                      <TextField
                        name="emailOtp"
                        variant="outlined"
                        hiddenLabel
                        value={formikOTPVerification.values.emailOtp}
                        placeholder='Email OTP Code'
                        onChange={formikOTPVerification.handleChange}
                        sx={{ minWidth: 80, maxWidth: '100%' }}
                      />
                      {formikOTPVerification.errors.emailOtp ? <Typography color="red">{formikOTPVerification.errors.emailOtp}</Typography> : null}
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mt: 1 }}
                    >
                      {timerIsActive2 ?
                        <Typography variant="subtitle1" color="text.secondary"
                          sx={{ mt: 1, textAlign: 'center' }}>
                          Resend a new OTP code in {seconds2} seconds
                        </Typography>
                        :
                        <Button variant="text" size="large"
                          onClick={() => {
                            setSeconds2(30);
                            setTimerIsActive2(true);
                            setEmailOTPexpirationDate(moment().add(300, 'seconds'));
                            sendEmailOTP();
                          }}>
                          Resend a new OTP Code to email
                        </Button>}
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="body1" color="text.secondary"
                        sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Email OTP Expiry: {emailOTPexpirationDate.format('MMM DD, YYYY hh:mm:ss a')}
                      </Typography>
                    </Stack>
                  </>}


                {/* <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={3}
                  sx={{ width: '100%' }}
                >
                  {otpSelected === 'sms' ?
                    <Button variant="text" size="large"
                      onClick={() => {
                        setotpSelected('email')
                        setSeconds2(30);
                        setTimerIsActive2(true);
                        setEmailOTPexpirationDate(moment().add(300, 'seconds'));
                        sendEmailOTP();
                      }}>
                      Use Email OTP
                    </Button>
                    :
                    <Button variant="text" size="large"
                      onClick={() => {
                        setotpSelected('sms')
                        setSeconds(30);
                        setTimerIsActive(true);
                        setsmsOTPexpirationDate(moment().add(300, 'seconds'));
                        sendMFAOTP();
                      }}>
                      Use Mobile OTP
                    </Button>}
                </Stack> */}

                <Divider sx={{ my: 1, width: '100%' }} />

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                  sx={{ mt: 3, width: '100%' }}
                >
                  <Button variant="contained" size="large"
                    onClick={() => {
                      setModalOTPVerification(false)
                    }}>
                    Cancel
                  </Button>

                  <Button type="submit" variant="contained" size="large">
                    Submit
                  </Button>
                </Stack>





                {/* <Stack sx={{ alignItems: "center" }}>
                  <Typography variant="body1" color="text.secondary"
                    sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                    Enter the OTP Code that we have sent to your Email Address
                  </Typography>

                  <TextField
                    name="emailOtp"
                    variant="outlined"
                    hiddenLabel
                    value={formikOTPVerification.values.emailOtp}
                    placeholder='Email OTP Code'
                    onChange={formikOTPVerification.handleChange}
                    sx={{ minWidth: 80, maxWidth: '100%' }}
                  />
                  {formikOTPVerification.errors.emailOtp ? <Typography color="red">{formikOTPVerification.errors.emailOtp}</Typography> : null}
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={3}
                  sx={{ mt: 1 }}
                >
                  {timerIsActive2 ?
                    <Typography variant="subtitle1" color="text.secondary"
                      sx={{ mt: 1, textAlign: 'center' }}>
                      Resend a new OTP code in {seconds2} seconds
                    </Typography>
                    :
                    <Button variant="text" size="large"
                      onClick={() => {
                        setSeconds2(30);
                        setTimerIsActive2(true);
                        setEmailOTPexpirationDate(moment().add(300, 'seconds'));
                        sendEmailOTP();
                      }}>
                      Resend a new OTP Code to email
                    </Button>}
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={3}
                  sx={{ mb: 2 }}
                >
                  <Typography variant="body1" color="text.secondary"
                    sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                    Email OTP Expiry: {emailOTPexpirationDate.format('MMM DD, YYYY hh:mm:ss a')}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                  sx={{ mt: 5, width: '100%' }}
                >
                  <Button variant="contained" size="large"
                    onClick={() => {
                      setModalOTPVerification(false)
                    }}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" size="large">
                    Submit
                  </Button>
                </Stack> */}

              </form>
            </FormControl>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalForgotPasswordInputEmail}
        onClose={() => {
          setModalForgotPasswordInputEmail(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalForgotPasswordInputEmail}>
          <Box sx={style}>

            <FormControl sx={{ display: "flex", lexDirection: "column", alignItems: "center", textAlign: 'center' }}>
              <form onSubmit={formikForgotPassword.handleSubmit} name='formikForgotPassword' key='formikForgotPassword'>

                <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
                  Forgot Password
                </Typography>

                <Divider sx={{ my: 3, width: '100%' }} />

                <Stack sx={{ alignItems: "center" }}>
                  <Typography id="transition-modal-description" variant="body1" color="text.secondary"
                    sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                    Please enter your Email Address that you used to register.
                  </Typography>
                  <Typography id="transition-modal-description" variant="body1" color="text.secondary"
                    sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                    We will send an OTP there.
                  </Typography>

                  <TextField
                    name="email"
                    variant="outlined"
                    hiddenLabel
                    value={formikForgotPassword.values.email}
                    placeholder='Email Address'
                    onChange={formikForgotPassword.handleChange}
                    sx={{ mb: 0, minWidth: '80%', maxWidth: '100%' }}
                  />
                  {formikForgotPassword.errors.email ? <Typography color="red">{formikForgotPassword.errors.email}</Typography> : null}
                </Stack>

                <Divider sx={{ my: 3, width: '100%' }} />

                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={3}
                  sx={{ mt: 2, width: '100%' }}
                >
                  <Button type="submit" variant="contained" size="large">
                    Submit
                  </Button>
                </Stack>

              </form>
            </FormControl>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalExpiredMobileOTP}
        onClose={() => {
          setModalExpiredMobileOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalExpiredMobileOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The Mobile OTP you have entered has expired.
        Click Resend code to receive a new Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalExpiredMobileOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalExpiredEmailOTP}
        onClose={() => {
          setModalExpiredEmailOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalExpiredEmailOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The Email OTP you have entered has expired.
  Click Resend code to receive a new Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalExpiredEmailOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalErrorOTP}
        onClose={() => {
          setModalErrorOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalErrorOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The OTP you have entered is incorrect.
              Please enter the correct OTP Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalErrorOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalSuccessfullChangePass}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalSuccessfullChangePass(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalSuccessfullChangePass}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`User has successfully changed password!`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalSuccessfullChangePass(false)
                window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalFirebaseSyncError}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalFirebaseSyncError(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalFirebaseSyncError}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`There is an issue with your account password!`}
            </Typography>
            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`User has most likely recently changed your password and an issue has arisen due to that.`}
            </Typography>
            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`Please contact the cocolife support team and provide your email address so that we can help you.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalFirebaseSyncError(false)
                window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalRegisteredWithDiffProvider}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalRegisteredWithDiffProvider(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalRegisteredWithDiffProvider}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {/* {`User has registered with a different login method.`} */}
              {`User has registered using ${diffProvider}.`}
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {/* {`User has registered with a different login method.`} */}
              {`Please use ${diffProvider} to login.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalRegisteredWithDiffProvider(false)
                // window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalFirebaseError}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalFirebaseError(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalFirebaseError}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {firebaseError}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalFirebaseError(false)
                // window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOldUserAccountHasBeenDeactivated}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalOldUserAccountHasBeenDeactivated(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOldUserAccountHasBeenDeactivated}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              This account has already been registered in the new system.
              {/* {oldUserDeactivatedUserAccount} */}
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Please use the Email Address that you used to register instead of your old username.
              {/* {oldUserDeactivatedUserAccount} */}
            </Typography>

            <Divider sx={{ my: 3, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 0 }}
              color="text.secondary"
            >
              If you think this is a mistake, please contact our customer support at 02 - 88107888 for assistance.
              {/* {oldUserDeactivatedUserAccount} */}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalOldUserAccountHasBeenDeactivated(false)
                // window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalNoPasswordForSocialiteAccount}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalNoPasswordForSocialiteAccount(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalNoPasswordForSocialiteAccount}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {/* {`User has registered with a different login method.`} */}
              {`You currently do not have a password since you have registered with ${diffProvider}.`}
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {/* {`User has registered with a different login method.`} */}
              {`Please use ${diffProvider} to login.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalNoPasswordForSocialiteAccount(false)
                setModalForgotPasswordInputEmail(false)
                // window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalServerError}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalServerError(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalServerError}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Server Error
            </Typography>

            <Divider sx={{ my: 3, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              There was an issue with the server.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Please try again.
            </Typography>

            <Button variant="contained"
              onClick={() => {
                setModalServerError(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalChangePassword}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalChangePassword(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalChangePassword}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Reset Password
            </Typography>

            <Divider sx={{ my: 3, width: '100%' }} />

            <FormControl sx={{ my: 1 }} fullWidth>
              <form onSubmit={formikChangePassword.handleSubmit} name='formikChangePassword' key='formikChangePassword'>

                <Stack sx={{ alignItems: "center" }}>
                  <Typography variant="body1" color="text.secondary"
                    sx={{ mb: 2, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                    Enter your New Passsword below
                  </Typography>

                  <TextField
                    name="newPassword"
                    variant="outlined"
                    hiddenLabel
                    value={formikChangePassword.values.newPassword}
                    placeholder='New Password'
                    onChange={formikChangePassword.handleChange}
                    type="password"
                    sx={{ minWidth: '80%', maxWidth: '100%' }}
                  />
                  {formikChangePassword.errors.newPassword ? <Typography color="red">{formikChangePassword.errors.newPassword}</Typography> : null}
                </Stack>

                <Stack sx={{ alignItems: "center" }}>
                  <Typography variant="body1" color="text.secondary"
                    sx={{ mb: 2, mt: 4, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                    Confirm your New Passsword below
                  </Typography>

                  <TextField
                    name="confirmPassword"
                    variant="outlined"
                    hiddenLabel
                    value={formikChangePassword.values.confirmPassword}
                    placeholder='Confirm your New Password'
                    onChange={formikChangePassword.handleChange}
                    type="password"
                    sx={{ minWidth: '80%', maxWidth: '100%' }}
                  />
                  {formikChangePassword.errors.confirmPassword ? <Typography color="red">{formikChangePassword.errors.confirmPassword}</Typography> : null}
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                  sx={{ mt: 5, width: '100%' }}
                >
                  <Button variant="contained" size="large"
                    onClick={() => {
                      setModalChangePassword(false)
                    }}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" size="large">
                    Submit
                  </Button>
                </Stack>

              </form>
            </FormControl>
          </Box>
        </Fade>
      </Modal>

      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />



      <Box sx={{ minHeight: "100vh", backgroundColor: "background.default" }}>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          <Paper variant="outlined" sx={{ py: 5, px: 4 }}>
            <Stack spacing={4}>
              <Box>
                <img src={cocolife} style={{ height: "25px" }} />
              </Box>
              <Typography variant="h5">Welcome back!</Typography>


              {isLoading ?
                <>
                  <Typography variant="h6" sx={{ textAlign: 'center' }}>Loading!</Typography>
                  {/* <Typography variant="body" sx={{ textAlign: 'center' }}>Connecting to API and DB...</Typography> */}
                </>
                :
                <>
                  <FormControl sx={{ my: 1, minWidth: 120 }} fullWidth>
                    <Typography variant="overline">Username or Email Address</Typography>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      placeholder="username or email"
                      value={username}
                      onChange={(event) => {
                        setUsername(event.target.value)
                      }}
                    />

                    <Typography variant="overline" sx={{ mt: 2 }}>Password</Typography>
                    <TextField
                      // sx={{ marginTop: "10px" }}
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      hiddenLabel
                      placeholder="password"
                      type="password"
                      value={password}
                      onChange={event => {
                        setPassword(event.target.value.replace(/\s/g, ''))
                      }}
                    />

                    <Button
                      variant="contained"
                      size="large"
                      sx={{ mt: 3 }}
                      onClick={handleMyPolicyLogin}
                    // href="/mypolicy"
                    >
                      Login to MyPolicy{" "}
                    </Button>

                    {/* <Button
                      variant="contained"
                      size="large"
                      sx={{ mt: 3 }}
                      onClick={handleGLOESLogin}
                    // href="/gloes"
                    >
                      Login to GLOES{" "}
                    </Button> */}

                    <Button
                      variant="contained"
                      size="large"
                      sx={{ mt: 3 }}
                      href="/registration"
                      onClick={(() => {
                        dispatch(setProvider('emailpassword'));
                      })}
                    >
                      Register
                    </Button>

                    <Divider sx={{ mt: 3 }}>Or Sign in with</Divider>
                    <Stack spacing={2} sx={{ mt: 3 }}>
                      {/* onClick={thing here} mapapasa eto sa props patry nung sa FB may sample function sa taas */}
                      <GoogleButton
                        onClick={() => {
                          handleMyPolicySocialiteLogin('google')
                        }}
                        message="Sign in with Google"
                      />
                      <FacebookButton
                        onClick={() => {
                          handleMyPolicySocialiteLogin('facebook')
                        }}
                        message="Sign in with Facebook"
                      />
                      <AppleButton
                        onClick={() => {
                          handleMyPolicySocialiteLogin('apple')
                        }}
                        message="Sign in with Apple ID"
                      />
                      {/* <DarkModeSwitch/> */}
                    </Stack>
                  </FormControl>
                  <Typography variant="caption">
                    Forgot your{" "}
                    {/* <Link
                      href="#"
                      color="text.linkActive"
                      onClick={(() => {
                        formikForgotUsername.resetForm()
                        setModalForgotUsernameInputEmail(true)
                      })}
                    // sx={{ textDecoration: "none" }}
                    // color="text.hint"
                    >
                      username
                    </Link>{" "}
                    or{" "} */}
                    <Link
                      href="#"
                      color="text.linkActive"
                      onClick={(() => {
                        formikOTPVerification.resetForm()
                        formikForgotPassword.resetForm()
                        formikChangePassword.resetForm()
                        console.log("otpSelected", otpSelected)
                        setModalForgotPasswordInputEmail(true)
                      })}
                    >
                      password
                    </Link>
                    ?
                  </Typography>
                </>}


              {/* <ClearPolicyButton/> */}
            </Stack>
          </Paper>
        </Container>
      </Box>
    </div >
  );
}
