import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import { Divider } from "@mui/material";
import React, {
  useState,
  useEffect,
} from 'react';
import APIErrorModal from "../components/APIErrorModal";
import APILoadingOverlay from "../components/APILoadingOverlay";
import UpdatePolicyButton from '../components/UpdatePolicyButton';
import { updatePolicyNumber, updatePolicyDetails } from "../features/Theming/PolicyNumberSlice";
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import axios from 'axios';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { el } from "date-fns/locale";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 350,
  maxWidth: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function AddRemovePolicy(props) {
  let dispatch = useDispatch();
  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [confirmUnregisterModalOpen, setConfirmUnregisterModalOpen] = useState(false);
  const [modalPrivacyPolicy, setModalPrivacyPolicy] = useState(false);
  const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);

  const [policyNumberList, setPolicyNumberList] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState('');
  const [registeredPoliciesList, setRegisteredPoliciesList] = useState([]);
  const [unregisteredPoliciesList, setUnregisteredPoliciesList] = useState([]);
  const [email, setEmail] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [policyAction, setPolicyAction] = useState('')

  const [modalServerError, setModalServerError] = useState(false);
  const [modalOTPVerification, setModalOTPVerification] = useState(false);
  const [modalErrorOTP, setModalErrorOTP] = useState(false);
  const [modalExpiredMobileOTP, setModalExpiredMobileOTP] = useState(false);
  const [modalExpiredEmailOTP, setModalExpiredEmailOTP] = useState(false);
  const [modalSuccessfulUnregister, setModalSuccessfulUnregister] = useState(false);
  const [modalSuccessfulRegister, setModalSuccessfulRegister] = useState(false);

  const [timerOnState, putTimerOnState] = useState();
  const [timerOnState2, putTimerOnState2] = useState();
  const [seconds, setSeconds] = useState(30);
  const [seconds2, setSeconds2] = useState(30);
  const [timerIsActive, setTimerIsActive] = useState(false);
  const [timerIsActive2, setTimerIsActive2] = useState(false);
  const [smsOTPexpirationDate, setsmsOTPexpirationDate] = useState(moment().add(300, 'seconds'));
  const [emailOTPexpirationDate, setEmailOTPexpirationDate] = useState(moment().add(300, 'seconds'));

  const [otpSelected, setotpSelected] = useState('email');
  const [mfaTableID, setmfaTableID] = useState(null);

  const [regiSchemaOTP, setRegiSchemaOTP] = useState(
    Yup.object().shape({
      otp: Yup.string()
        .required('Mobile OTP is required'),
    })
  );

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    if (otpSelected === 'sms') {
      setRegiSchemaOTP(
        Yup.object().shape({
          otp: Yup.string()
            .required('Mobile OTP is required'),
        })
      )
    } else {
      setRegiSchemaOTP(
        Yup.object().shape({
          emailOtp: Yup.string()
            .required('Email OTP is required'),
        })
      )
    }
  }, [otpSelected])

  useEffect(() => {
    if (timerIsActive) {
      // console.log('timerIsActive', timerIsActive)
      const timer = setInterval(() => {
        // console.log('seconds', seconds)
        setSeconds((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
      }, 1000);

      if (seconds <= 0) {
        console.log('timer done')
        setSeconds(30)
        setTimerIsActive(false)
      }

      putTimerOnState(timer);

      return () => {
        clearInterval(timer);
      };
    }
  }, [seconds, timerIsActive])

  useEffect(() => {
    if (timerIsActive2) {
      // console.log('timerIsActive2', timerIsActive2)
      const timer2 = setInterval(() => {
        // console.log('seconds', seconds)
        setSeconds2((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
      }, 1000);

      if (seconds2 <= 0) {
        console.log('timer2 done')
        setSeconds2(30)
        setTimerIsActive2(false)
      }

      putTimerOnState2(timer2);

      return () => {
        clearInterval(timer2);
      };
    }
  }, [seconds2, timerIsActive2])

  useEffect(() => {
    async function init() {

      await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByPolicyNumber', {
        policyNumber: POLICY_DATA,
      }).then(async (getUserByPolicyNumberRes) => {
        if (getUserByPolicyNumberRes.data.user === false) {
          //  big error here
          console.log("User not found")
          return
        }
        console.log("getUserByPolicyNumberRes", getUserByPolicyNumberRes)
        console.log("getUserByPolicyNumberRes.data.user.recordset[0]", getUserByPolicyNumberRes.data.user.recordset[0])

        setEmail(getUserByPolicyNumberRes.data.user.recordset[0].email)
        setMobileNumber(getUserByPolicyNumberRes.data.user.recordset[0].mobile_number)

        setIsLoading(false)
      })

    }
    init()
  }, [])


  useEffect(() => {
    async function init() {
      try {
        let registeredPolicyNumberList = [
          POLICY_DATA,
        ]
        let unregisteredPolicyNumberList = []

        await axios.post(process.env.REACT_APP_API_URL + '/api/getAllPoliciesByCurrentPolicy', {
          policyNumber: POLICY_DATA
        }).then(async (getAllPoliciesByCurrentPolicyRes) => {
          // console.log("getAllPoliciesByCurrentPolicyRes", getAllPoliciesByCurrentPolicyRes);
          console.log("getAllPoliciesByCurrentPolicyRes.data.user.recordset", getAllPoliciesByCurrentPolicyRes.data.user.recordset);
          if (getAllPoliciesByCurrentPolicyRes.data.user === false) {
            console.log("error: user has no policies")
          } else {
            getAllPoliciesByCurrentPolicyRes.data.user.recordset.map((policyFromDB, index) => {
              console.log("policyFromDB", policyFromDB)
              console.log("policyFromDB.registered == 'true'", policyFromDB.registered == 'true')
              if (POLICY_DATA != policyFromDB.policy_number && policyFromDB.registered == 'true') {
                registeredPolicyNumberList = [...registeredPolicyNumberList, policyFromDB.policy_number]
              } else if (POLICY_DATA != policyFromDB.policy_number && policyFromDB.registered == 'false') {
                if (unregisteredPolicyNumberList.length > 0) {
                  unregisteredPolicyNumberList = [...unregisteredPolicyNumberList, policyFromDB.policy_number]
                } else {
                  unregisteredPolicyNumberList = [policyFromDB.policy_number]
                }
              }
            })

            setPolicyNumberList([...registeredPolicyNumberList]);
            let registeredPoliciesTMP = [];
            await Promise.all(
              registeredPolicyNumberList.map(async (policyNumber, index) => {
                // console.log("policyNumber map", policyNumber)
                let policyProfileReqData = {
                  // policyNumber: 'T0095469',
                  policyNumber: policyNumber,
                }

                await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile',
                  policyProfileReqData
                ).then((res) => {
                  console.log("getPolicyProfile res.data.Data", res.data.Data);

                  if (res === 'null') {
                    setapiName('getPolicyProfile')
                    setapiResponse({
                      Code: '408',
                      Message: "API request timeout."
                    })
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getPolicyProfile res is null')
                    return
                  }

                  if (res.data.Success) {
                    policyNumber = {
                      "Plan": res.data.Data.PlanName,
                      "PolicyNumber": policyNumber,
                      "PolicyStatus": res.data.Data.Status,
                    }
                    console.log('policyNumber to add', policyNumber)
                    if (policyNumber) {
                      if (registeredPoliciesTMP === null || registeredPoliciesTMP.length === 0) {
                        console.log('First Policy is', policyNumber)
                        registeredPoliciesTMP = [policyNumber];
                      } else {
                        console.log('Adding new policy', policyNumber)
                        registeredPoliciesTMP = [...registeredPoliciesTMP, policyNumber];
                      }
                    } else {
                      if (registeredPoliciesTMP === null || registeredPoliciesTMP.length === 0) {
                        console.log('First Policy is', policyNumber)
                        registeredPoliciesTMP = [policyNumber];
                      } else {
                        console.log('Adding new policy', policyNumber)
                        registeredPoliciesTMP = [...registeredPoliciesTMP, policyNumber];
                      }
                    }

                    console.log('Setting all policies to state')
                    setRegisteredPoliciesList([...registeredPoliciesTMP]);
                  } else {
                    setapiName('getPolicyProfile')
                    setapiResponse(res.data)
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getPolicyProfile res', res.data.Response)
                    return
                  }
                })
              })
            )

            let unregisteredPoliciesTMP = [];
            await Promise.all(
              unregisteredPolicyNumberList.map(async (policyNumber, index) => {
                // console.log("policyNumber map", policyNumber)
                let policyProfileReqData = {
                  // policyNumber: 'T0095469',
                  policyNumber: policyNumber,
                }

                await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile',
                  policyProfileReqData
                ).then((res) => {
                  console.log("getPolicyProfile res.data.Data", res.data.Data);

                  if (res === 'null') {
                    setapiName('getPolicyProfile')
                    setapiResponse({
                      Code: '408',
                      Message: "API request timeout."
                    })
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getPolicyProfile res is null')
                    return
                  }

                  if (res.data.Success) {
                    policyNumber = {
                      "Plan": res.data.Data.PlanName,
                      "PolicyNumber": policyNumber,
                      "PolicyStatus": res.data.Data.Status,
                    }
                    console.log('policyNumber to add', policyNumber)
                    if (policyNumber) {
                      if (unregisteredPoliciesTMP === null || unregisteredPoliciesTMP.length === 0) {
                        console.log('First Policy is', policyNumber)
                        unregisteredPoliciesTMP = [policyNumber];
                      } else {
                        console.log('Adding new policy', policyNumber)
                        unregisteredPoliciesTMP = [...unregisteredPoliciesTMP, policyNumber];
                      }
                    } else {
                      if (unregisteredPoliciesTMP === null || unregisteredPoliciesTMP.length === 0) {
                        console.log('First Policy is', policyNumber)
                        unregisteredPoliciesTMP = [policyNumber];
                      } else {
                        console.log('Adding new policy', policyNumber)
                        unregisteredPoliciesTMP = [...unregisteredPoliciesTMP, policyNumber];
                      }
                    }

                    console.log('Setting all policies to state')
                    setUnregisteredPoliciesList([...unregisteredPoliciesTMP]);
                  } else {
                    setapiName('getPolicyProfile')
                    setapiResponse(res.data)
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getPolicyProfile res', res.data.Response)
                    return
                  }
                })
              })
            )

            setIsLoading(false)
          }
        })
      } catch (error) {

        setIsLoading(false)
        console.log("axios error", error);
      }

    }

    init()
  }, [])


  const sendMFAOTP = async (type = 'sms') => {
    console.log('called sendMFAOTP');
    try {
      console.log("createMFAOTP axios call")
      await axios.post(process.env.REACT_APP_API_URL + '/api/createMFAOTP', {
        type,
        target: mobileNumber,
      }).then(async (res) => {
        console.log("sendMFAOTP res", res)
        let mfaTableID = res.data.data.mfaTableID;
        console.log("mfaTableID", mfaTableID)
        setmfaTableID(mfaTableID);

        // await sendEmailOTP()
      })
    } catch (error) {
      console.log("axios error", error);
    }
  }

  const verifyMFAOTP = async (type = 'sms') => {
    console.log("formikOTPVerification.values.otp != ''", formikOTPVerification.values.otp != '')
    if (formikOTPVerification.values.otp.trim() != '' && formikOTPVerification.values.otp != null) {
      if (!moment().isSameOrAfter(smsOTPexpirationDate)) {
        try {
          await axios.post(process.env.REACT_APP_API_URL + '/api/verifyMFAOTP', {
            mfaTableID: mfaTableID,
            otpCode: formikOTPVerification.values.otp,
          }).then(async (res) => {
            console.log("res", res)
            console.log("res.data.result", res.data.result)
            if (res.data.result === 'success') {
              console.log("verifyMailOTP success")
              setIsLoading(false);
              setModalOTPVerification(false)

              if (policyAction === 'unregister') {
                handleUnregisterPolicy(selectedPolicy)
                setModalSuccessfulUnregister(true)
              } else if (policyAction === 'register') {
                handleRegisterPolicy(selectedPolicy)
                setModalSuccessfulRegister(true)
              }

              formikOTPVerification.resetForm()
              return { success: true }
            } else {
              // sendMFAOTP('sms')
              setModalErrorOTP(true);
              setIsLoading(false)
              return { success: false }
            }
          })
        } catch (error) {
          console.log("axios error", error);
          setIsLoading(false);
          return { success: false }
          // return {
          //   error: "There was na issue with the MFA. Please contact our customer support."
          // }
        }
      } else {
        setIsLoading(false)
        console.log("moment().isSameOrAfter(smsOTPexpirationDate)", moment().isSameOrAfter(smsOTPexpirationDate))
        setModalExpiredMobileOTP(true)
      }
    } else {
      setIsLoading(false)
      console.log("mobileotp is blank")
      formikOTPVerification.setErrors({ otp: 'Mobile OTP is required' });
    }
  }

  const sendEmailOTP = async (type = 'sms') => {
    console.log('called sendEmailOTP');
    try {
      console.log("sendMail axios call")
      console.log("email", email)

      let type;
      if (policyAction == 'unregister') {
        type = 'remove-policy'
      } else {
        type = 'add-policy'
      }

      await axios.post(process.env.REACT_APP_API_URL + '/api/otpGenerator', {
        email: email,
        type: type,
      }).then(async (res) => {
        console.log("sendMail res", res)
      })
    } catch (error) {
      console.log("axios error", error);
    }
  }

  const verifyMailOTP = async (type = 'sms') => {
    console.log("formikOTPVerification.values.emailOtp", formikOTPVerification.values.emailOtp != '')
    if (formikOTPVerification.values.emailOtp.trim() != '' && formikOTPVerification.values.emailOtp != null) {
      console.log("formikOTPVerification.values.emailOtp.split('').length", formikOTPVerification.values.emailOtp.split('').length)
      if (formikOTPVerification.values.emailOtp.split('').length == 6) {
        if (!moment().isSameOrAfter(emailOTPexpirationDate)) {
          try {
            console.log("sendMail axios call")
            await axios.post(process.env.REACT_APP_API_URL + '/api/otpValidate', {
              token: formikOTPVerification.values.emailOtp,
              email: email,
              type: 'change-password',
            }).then(async (res) => {
              console.log("sendMail res", res)

              if (res.data.data.Success) {
                console.log("verifyMailOTP success")
                setIsLoading(false);
                setModalOTPVerification(false)

                if (policyAction === 'unregister') {
                  handleUnregisterPolicy(selectedPolicy)
                  setModalSuccessfulUnregister(true)
                } else if (policyAction === 'register') {
                  handleRegisterPolicy(selectedPolicy)
                  setModalSuccessfulRegister(true)
                }

                formikOTPVerification.resetForm()
              } else if (res.data.data.Error == 'Expired OTP') {
                setModalExpiredEmailOTP(true);
                setIsLoading(false)
              } else {
                setModalErrorOTP(true);
                setIsLoading(false)
              }

            })
          } catch (error) {
            console.log("axios error", error);
            setIsLoading(false);
            return { success: false }
            // return {
            //   error: "There was na issue with the MFA. Please contact our customer support."
            // }
          }
        } else {
          console.log("moment().isSameOrAfter(emailOTPexpirationDate)", moment().isSameOrAfter(emailOTPexpirationDate))
          setModalExpiredEmailOTP(true)
          setIsLoading(false)
        }
      } else {
        setModalErrorOTP(true);
        setIsLoading(false)
      }

    } else {
      setIsLoading(false)
      console.log("emailOtp is blank")
      formikOTPVerification.setErrors({ emailOtp: 'Email OTP is required' });
    }
  }

  const formikOTPVerification = useFormik({
    initialValues: {
      otp: "",
      emailOtp: "",
    },
    enableReinitialize: true,
    validationSchema: regiSchemaOTP,
    onSubmit: async (values) => {
      console.log("onSubmit called")
      console.log("setIsLoading true")

      console.log("moment().isSameOrAfter(smsOTPexpirationDate)", moment().isSameOrAfter(smsOTPexpirationDate))
      if (otpSelected === 'sms') {
        verifyMFAOTP()
      } else {
        verifyMailOTP()
      }

      // console.log({ values otp != '' || })
    }
  })

  const handleUnregisterPolicy = async (policyNumber) => {
    if (registeredPoliciesList.length > 1) {
      if (policyNumber === POLICY_DATA) {
        let newPolicySelected = false
        registeredPoliciesList.map(async (policy, index) => {
          if (policy.PolicyNumber != POLICY_DATA && !newPolicySelected) {
            await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
              policyNumber: policy.PolicyNumber,
            })
              .then(async (res) => {
                console.log("getPolicyProfile res.data.Data", res.data.Data);

                if (res === 'null') {
                  setIsLoading(false)
                  setapiName('getPolicyProfile')
                  setapiResponse({
                    Code: '408',
                    Message: "API request timeout."
                  })
                  setAPIErrorModalOpen(true)
                  console.log('issue', 'getPolicyProfile res is null')
                  return
                } else if (res.data.Success) {
                  // dispatch(updatePolicyNumber(policyNumber));
                  let isVL = res.data.Data?.ProductType === 'VL'
                  let IsTermPlan = res.data.Data?.IsTermPlan === 'True'

                  let policyDetails = {
                    policyNumber: policy.PolicyNumber,
                    isVL: isVL,
                    isTermPlan: IsTermPlan,
                  }
                  dispatch(updatePolicyDetails(policyDetails));

                  newPolicySelected = true;

                } else {
                  setIsLoading(false)
                  setapiName('getPolicyProfile')
                  setapiResponse(res.data)
                  setAPIErrorModalOpen(true)
                  console.log('issue', 'getPolicyProfile res', res.data.Response)
                  return
                }
              })
          }
        })
      }

      await axios.post(process.env.REACT_APP_API_URL + '/api/unregisterPolicy', {
        policyNumber
      }).then((res) => {
        console.log("unregisterPolicy res", res);

        if (res.data.data.Success) {
          console.log("unregisterPolicy success");
          let newRegisteredPoliciesList = [];

          registeredPoliciesList.map((policy, index) => {
            if (policy.PolicyNumber != policyNumber) {
              if (newRegisteredPoliciesList.length > 0) {
                newRegisteredPoliciesList = [...newRegisteredPoliciesList, policy]
              } else {
                newRegisteredPoliciesList = [policy]
              }
            } else {
              let newUnregisteredPoliciesList = unregisteredPoliciesList;

              newUnregisteredPoliciesList = [...newUnregisteredPoliciesList, policy]

              setUnregisteredPoliciesList(newUnregisteredPoliciesList);
            }
          })
          setRegisteredPoliciesList(newRegisteredPoliciesList);
        } else {
          setModalServerError(true)
          console.log('unregisterPolicy issue')
        }
      }).catch((error) => {
        console.log('unregisterPolicy error', error)
        setModalServerError(true)
      })
    }
  }

  const handleRegisterPolicy = async (policyNumber) => {
    console.log("moment", moment().format('MM-DD-YYYY hh:mm:ss a'))

    await axios.post(process.env.REACT_APP_API_URL + '/api/registerPolicy', {
      policyNumber
    }).then((res) => {
      console.log("registerPolicy res", res);

      if (res.data.registeredPolicy) {
        let newUnregisteredPoliciesList = [];

        unregisteredPoliciesList.map((policy, index) => {
          if (policy.PolicyNumber != policyNumber) {
            if (newUnregisteredPoliciesList.length > 0) {
              newUnregisteredPoliciesList = [...newUnregisteredPoliciesList, policy]
            } else {
              newUnregisteredPoliciesList = [policy]
            }
          } else {
            let newRegisteredPoliciesList = registeredPoliciesList;

            newRegisteredPoliciesList = [...newRegisteredPoliciesList, policy]

            setRegisteredPoliciesList(newRegisteredPoliciesList);
          }
        })

        setUnregisteredPoliciesList(newUnregisteredPoliciesList);
        setAgreedToPrivacyPolicy(false)
      } else {
        setAgreedToPrivacyPolicy(false)
        setModalServerError(true)
        console.log('registerPolicy issue')
      }
    })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOTPVerification}
        onClose={() => {
          setModalOTPVerification(false)
          formikOTPVerification.resetForm()
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOTPVerification}>
          <Box sx={style}>

            <FormControl sx={{ display: "flex", lexDirection: "column", alignItems: "center", textAlign: 'center' }}>
              <form onSubmit={formikOTPVerification.handleSubmit} name='formikOTPVerification' key='formikOTPVerification'>

                <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
                  {policyAction == 'register' ? 'Add ' : 'Remove '} Policy OTP Verification
                </Typography>


                <Divider sx={{ my: 3, width: '100%' }} />


                {otpSelected === 'sms' ?
                  <>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Enter the OTP Code that we have sent to your Mobile Number
                      </Typography>

                      <TextField
                        name="otp"
                        variant="outlined"
                        hiddenLabel
                        value={formikOTPVerification.values.otp}
                        placeholder='Mobile OTP Code'
                        onChange={formikOTPVerification.handleChange}
                        sx={{ minWidth: 80, maxWidth: '100%' }}
                      />
                      {formikOTPVerification.errors.otp ? <Typography color="red">{formikOTPVerification.errors.otp}</Typography> : null}
                    </Stack>


                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mt: 1 }}
                    >
                      {timerIsActive ?
                        <Typography variant="subtitle1" sx={{ mt: 1, textAlign: 'center' }}>
                          Resend a new OTP code in {seconds} seconds
                        </Typography>
                        :
                        <Button variant="text" size="large"
                          onClick={() => {
                            setSeconds(30);
                            setTimerIsActive(true);
                            setsmsOTPexpirationDate(moment().add(300, 'seconds'));
                            sendMFAOTP();
                          }}>
                          Resend a new OTP Code to mobile
                        </Button>}
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="body1" sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Mobile OTP Expiry: {smsOTPexpirationDate.format('MMM DD, YYYY hh:mm:ss a')}
                      </Typography>
                    </Stack>
                  </>
                  :
                  <>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Enter the OTP Code that we have sent to your Email Address
                      </Typography>

                      <TextField
                        name="emailOtp"
                        variant="outlined"
                        hiddenLabel
                        value={formikOTPVerification.values.emailOtp}
                        placeholder='Email OTP Code'
                        onChange={formikOTPVerification.handleChange}
                        sx={{ minWidth: 80, maxWidth: '100%' }}
                      />
                      {formikOTPVerification.errors.emailOtp ? <Typography color="red">{formikOTPVerification.errors.emailOtp}</Typography> : null}
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mt: 1 }}
                    >
                      {timerIsActive2 ?
                        <Typography variant="subtitle1" sx={{ mt: 1, textAlign: 'center' }}>
                          Resend a new OTP code in {seconds2} seconds
                        </Typography>
                        :
                        <Button variant="text" size="large"
                          onClick={() => {
                            setSeconds2(30);
                            setTimerIsActive2(true);
                            setEmailOTPexpirationDate(moment().add(300, 'seconds'));
                            sendEmailOTP();
                          }}>
                          Resend a new OTP Code to email
                        </Button>}
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="body1" sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Email OTP Expiry: {emailOTPexpirationDate.format('MMM DD, YYYY hh:mm:ss a')}
                      </Typography>
                    </Stack>
                  </>}

                <Divider sx={{ my: 1, width: '100%' }} />

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                  sx={{ mt: 5, width: '100%' }}
                >
                  {otpSelected === 'sms' ?
                    <>
                      {timerIsActive2 ?
                        <>
                          <Button variant="text" size="large"
                            disabled>
                            Use Email OTP {seconds2} Seconds
                          </Button>
                        </>
                        :
                        <>
                          <Button variant="text" size="large"
                            onClick={() => {
                              setotpSelected('email')
                              setSeconds2(30);
                              setTimerIsActive2(true);
                              setEmailOTPexpirationDate(moment().add(300, 'seconds'));
                              sendEmailOTP();
                            }}>
                            Use Email OTP
                          </Button>
                        </>
                      }
                    </>
                    :
                    <>
                      {timerIsActive ?
                        <>
                          <Button variant="text" size="large"
                            disabled>
                            Use Mobile in {seconds} Seconds
                          </Button>
                        </>
                        :
                        <>
                          <Button variant="text" size="large"
                            onClick={() => {
                              setotpSelected('sms')
                              setSeconds(30);
                              setTimerIsActive(true);
                              setsmsOTPexpirationDate(moment().add(300, 'seconds'));
                              sendMFAOTP();
                            }}>
                            Use Mobile OTP
                          </Button>
                        </>
                      }
                    </>
                  }
                  <Button type="submit" variant="contained" size="large">
                    Submit
                  </Button>
                </Stack>

              </form>
            </FormControl>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalExpiredMobileOTP}
        onClose={() => {
          setModalExpiredMobileOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalExpiredMobileOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The Mobile OTP you have entered has expired.
        Click Resend code to receive a new Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalExpiredMobileOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalExpiredEmailOTP}
        onClose={() => {
          setModalExpiredEmailOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalExpiredEmailOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The Email OTP you have entered has expired.
  Click Resend code to receive a new Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalExpiredEmailOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalErrorOTP}
        onClose={() => {
          setModalErrorOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalErrorOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The OTP you have entered is incorrect.
              Please enter the correct OTP Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalErrorOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={confirmUnregisterModalOpen}
        onClose={() => {
          setConfirmUnregisterModalOpen(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={confirmUnregisterModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Unregister Policy {selectedPolicy}?
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Are you sure you want to unregister this Policy?
            </Typography>


            <Button variant="contained" onClick={() => {
              setConfirmUnregisterModalOpen(false)
              if (otpSelected === 'sms') {
                sendMFAOTP()
              } else {
                sendEmailOTP()
              }
              setModalOTPVerification(true)
              formikOTPVerification.resetForm()
            }} sx={{ mt: 4 }}>
              Unregister
            </Button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalSuccessfulUnregister}
        onClose={() => {
          setModalSuccessfulUnregister(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalSuccessfulUnregister}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Unregistered Policy {selectedPolicy}!
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Policy have been successfully unregistered
            </Typography>


            <Button variant="contained" onClick={() => {
              setModalSuccessfulUnregister(false)
            }} sx={{ mt: 4 }}>
              Ok
            </Button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalSuccessfulRegister}
        onClose={() => {
          setModalSuccessfulRegister(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalSuccessfulRegister}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Registered Policy {selectedPolicy}!
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Policy have been successfully registered
            </Typography>


            <Button variant="contained" onClick={() => {
              setModalSuccessfulRegister(false)
            }} sx={{ mt: 4 }}>
              Ok
            </Button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalServerError}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalServerError(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalServerError}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Server Error
            </Typography>

            <Divider sx={{ my: 3, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              There was an issue with the server.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Please try again.
            </Typography>

            <Button variant="contained"
              onClick={() => {
                window.location.reload(true);
                setModalServerError(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalPrivacyPolicy}
        onClose={() => {
          setModalPrivacyPolicy(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalPrivacyPolicy}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Privacy Policy Statement
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Your privacy is important to us. By using this site, you agree to
              the use of cookies and your personal information in accordance
              with our{" "}
              <Link
                href="https://www.cocolife.com/about/privacy-policy/"
                target="_blank"
                sx={{ textDecoration: "none" }}
                color="text.linkActive"
              >
                Privacy Policy
              </Link>
            </Typography>

            <Stack direction="row">
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography
                    color="text.secondary">
                    I AGREE to the Privacy Policy{' '}
                  </Typography>}
                checked={agreedToPrivacyPolicy}
                sx={{ mt: 4 }}
                onClick={() => {
                  setAgreedToPrivacyPolicy(!agreedToPrivacyPolicy)
                }} />
            </Stack>
            <Stack direction="row"
              sx={{ maxWidth: '70%' }}>
              <Typography
                sx={{ textAlign: 'left', marginLeft: 4, fontSize: '14px' }}
                color="text.secondary">
                Please {' '}
                <Link
                  href="/privacy-policy"
                  target="_blank"
                  color="text.linkActive"
                >
                  click here {' '}
                </Link>
                to learn more about our Data Privacy.
              </Typography>
            </Stack>

            {agreedToPrivacyPolicy ?
              <Button variant="contained" onClick={() => {
                setModalPrivacyPolicy(false)
                if (otpSelected === 'sms') {
                  sendMFAOTP()
                } else {
                  sendEmailOTP()
                }
                setModalOTPVerification(true)
                formikOTPVerification.resetForm()
              }} sx={{ mt: 4 }}>
                Proceed
              </Button>
              :
              <Button variant="contained" sx={{ mt: 4 }} disabled>
                Proceed
              </Button>}
          </Box>
        </Fade>
      </Modal>

      <Typography variant="h4" sx={{ mb: 2 }} color="text.primary">
        Add or Remove Policy
      </Typography>

      <APILoadingOverlay customlabel={loadingCustomLabel} open={isLoading} setOnParent={(value) => setIsLoading(value)}>
        <Typography variant="body1" color="text.secondary">
          Your current policy registration is shown below. If you have more than
          one policy, you can add other policies by clicking the "Register this"
          link under the My Other Policies section.
        </Typography>

        <Box sx={{ mt: 5 }}>
          <Typography variant="subtitle2">Registered Policies</Typography>
          <Paper variant="outlined" sx={{ mt: 1, p: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "600" }}>Policy Number</TableCell>
                    <TableCell align="left" sx={{ fontWeight: "600" }}>Plan</TableCell>
                    <TableCell align="left" sx={{ fontWeight: "600" }}>Policy Status</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "600" }}>Actions</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {registeredPoliciesList.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.PolicyNumber}
                      </TableCell>
                      <TableCell align="left">{row.Plan}</TableCell>
                      <TableCell align="left">{row.PolicyStatus}</TableCell>
                      {/* <TableCell align="left">{row.changeDate}</TableCell> */}
                      <TableCell align="center">
                        {registeredPoliciesList.length > 1 ?
                          <Button
                            onClick={() => {
                              formikOTPVerification.resetForm()
                              setPolicyAction('unregister')
                              setConfirmUnregisterModalOpen(true)
                              setSelectedPolicy(row.PolicyNumber)
                            }}
                            variant="contained"
                            color="primary">
                            Unregister This
                          </Button>
                          : null}
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>

        <Box sx={{ mt: 7 }}>
          <Typography variant="subtitle2">Other Policies (Unregistered Policies)</Typography>
          <Paper variant="outlined" sx={{ mt: 1, p: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "600" }}>Policy Number</TableCell>
                    <TableCell align="left" sx={{ fontWeight: "600" }}>Plan</TableCell>
                    <TableCell align="left" sx={{ fontWeight: "600" }}>Policy Status</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "600" }}>Actions</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {unregisteredPoliciesList.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.PolicyNumber}
                      </TableCell>
                      <TableCell align="left">{row.Plan}</TableCell>
                      <TableCell align="left">{row.PolicyStatus}</TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            formikOTPVerification.resetForm()
                            setPolicyAction('register')
                            setModalPrivacyPolicy(true)
                            setSelectedPolicy(row.PolicyNumber)
                          }}
                          variant="contained"
                          color="primary">
                          Register This
                        </Button>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </APILoadingOverlay>
    </Box>
  );
}
