import React, {
  useState,
  useEffect,
} from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik, useField, useFormikContext } from "formik";
import moment from "moment-timezone";
import CryptoJS from 'crypto-js'
import * as Yup from 'yup';
import { useAuth } from "../FirebaseAuth";
import { auth, firestoreDB } from "../Firebase/firebase";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router';
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import APILoadingOverlay from "../components/APILoadingOverlay";
import APIErrorModal from "../components/APIErrorModal";
import axios from 'axios';

export default function ProfileAccount() {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const LOADING_STATE = useSelector((state) => state.loadingState.value)
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  // const USERNAME = useSelector((state) => state.username.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const { firebaseSignup, firebaseLogin, getCurrentFirebaseUser, firebaseUpdatePassword } = useAuth()

  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [suffix, setSuffix] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [userID, setUserID] = useState('')
  const [otpMode, setOTPmode] = useState('')

  const [formValues, setFormValues] = useState({});

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);
  const [modalOTPVerification, setModalOTPVerification] = useState(false);
  const [modalWrongPassword, setModalWrongPassword] = useState(false);
  const [modalErrorOTP, setModalErrorOTP] = useState(false);
  const [modalExpiredMobileOTP, setModalExpiredMobileOTP] = useState(false);
  const [modalExpiredEmailOTP, setModalExpiredEmailOTP] = useState(false);
  const [modalSuccessfullChangePass, setModalSuccessfullChangePass] = useState(false);
  const [modalServerError, setModalServerError] = useState(false);
  const [modalChangeRequestSuccess, setModalChangeRequestSuccess] = useState(false);
  const [emailExistsErrorModalOpen, setEmailExistsErrorModal] = React.useState(false);
  const [mobileNumberExistsErrorModalOpen, setMobileNumberExistsErrorModal] = useState(false);

  const [timerOnState, putTimerOnState] = useState();
  const [timerOnState2, putTimerOnState2] = useState();
  const [seconds, setSeconds] = useState(30);
  const [seconds2, setSeconds2] = useState(30);
  const [timerIsActive, setTimerIsActive] = useState(false);
  const [timerIsActive2, setTimerIsActive2] = useState(false);
  const [smsOTPexpirationDate, setsmsOTPexpirationDate] = useState(moment().add(300, 'seconds'));
  const [emailOTPexpirationDate, setEmailOTPexpirationDate] = useState(moment().add(300, 'seconds'));

  const [mfaTableID, setmfaTableID] = useState(null);

  const [regiSchemaOTP, setRegiSchemaOTP] = useState(
    Yup.object().shape({
      otp: Yup.string()
        .required('Mobile OTP is required'),
    })
  );

  const [otpSelected, setotpSelected] = useState('email');

  const [hasChangePassword, setHasChangePassword] = useState(false);
  const [hasPendingProfileChange, setHasPendingProfileChange] = useState(false);
  const [profileChangeRequestType, setProfileChangeRequestType] = useState('');

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    maxWidth: 600,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    if (otpSelected === 'sms') {
      setRegiSchemaOTP(
        Yup.object().shape({
          otp: Yup.string()
            .required('Mobile OTP is required'),
        })
      )
    } else {
      setRegiSchemaOTP(
        Yup.object().shape({
          emailOtp: Yup.string()
            .required('Email OTP is required'),
        })
      )
    }
  }, [otpSelected])

  useEffect(() => {
    if (timerIsActive) {
      // console.log('timerIsActive', timerIsActive)
      const timer = setInterval(() => {
        // console.log('seconds', seconds)
        setSeconds((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
      }, 1000);

      if (seconds <= 0) {
        console.log('timer done')
        setSeconds(30)
        setTimerIsActive(false)
      }

      putTimerOnState(timer);

      return () => {
        clearInterval(timer);
      };
    }
  }, [seconds, timerIsActive])

  useEffect(() => {
    if (timerIsActive2) {
      // console.log('timerIsActive2', timerIsActive2)
      const timer2 = setInterval(() => {
        // console.log('seconds', seconds)
        setSeconds2((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
      }, 1000);

      if (seconds2 <= 0) {
        console.log('timer2 done')
        setSeconds2(30)
        setTimerIsActive2(false)
      }

      putTimerOnState2(timer2);

      return () => {
        clearInterval(timer2);
      };
    }
  }, [seconds2, timerIsActive2])

  useEffect(() => {
    async function init() {

      await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByPolicyNumber', {
        policyNumber: POLICY_DATA,
      }).then(async (getUserByPolicyNumberRes) => {
        if (getUserByPolicyNumberRes.data.user === false) {
          //  big error here
          console.log("User not found")
          return
        }
        console.log("getUserByPolicyNumberRes", getUserByPolicyNumberRes)
        console.log("getUserByPolicyNumberRes.data.user.recordset[0]", getUserByPolicyNumberRes.data.user.recordset[0])


        setFirstName(getUserByPolicyNumberRes.data.user.recordset[0].first_name)
        if (getUserByPolicyNumberRes.data.user.recordset[0].middle_name != null) {
          setMiddleName(getUserByPolicyNumberRes.data.user.recordset[0].middle_name)
        }
        setLastName(getUserByPolicyNumberRes.data.user.recordset[0].last_name)
        if (getUserByPolicyNumberRes.data.user.recordset[0].suffix != null) {
          setSuffix(getUserByPolicyNumberRes.data.user.recordset[0].suffix)
        }
        setEmail(getUserByPolicyNumberRes.data.user.recordset[0].email)
        setMobileNumber(getUserByPolicyNumberRes.data.user.recordset[0].mobile_number)
        setUsername(getUserByPolicyNumberRes.data.user.recordset[0].username)
        // setUserID(getUserByPolicyNumberRes.data.user.recordset[0].user_id)

        if (getUserByPolicyNumberRes.data.user.recordset[0].provider === 'emailpassword') {
          setHasChangePassword(true)
        } else {
          setHasChangePassword(false)
          setIsLoading(false);
        }

        setIsLoading(false)
      })

      await axios.post(process.env.REACT_APP_API_URL + '/api/getPendingChangeRequestByPolicyNumber', {
        policyNumber: POLICY_DATA,
      }).then(async (res) => {
        console.log("getPendingChangeRequestByPolicyNumber res", res)
        if (res.data.Success == true) {
          console.log("getPendingChangeRequestByPolicyNumber res success")

          var data = res.data.Data

          if (data.type == 'email-change') {
            setProfileChangeRequestType("Email")
          } else if (data.type == 'mobile-number-change') {
            setProfileChangeRequestType("Mobile Number")
          }

          setHasPendingProfileChange(true)

          setIsLoading(false);
        } else {
          console.log("getPendingChangeRequestByPolicyNumber res fail")

          setIsLoading(false);
        }
        setIsLoading(false);
      }).catch((error) => {
        console.log('getPendingChangeRequestByPolicyNumber error', error)
        setIsLoading(false);
      })

    }
    init()
  }, [])

  const sendMFAOTP = async (type = 'sms') => {
    console.log('called sendMFAOTP');
    try {
      console.log("createMFAOTP axios call")
      await axios.post(process.env.REACT_APP_API_URL + '/api/createMFAOTP', {
        type,
        target: mobileNumber,
      }).then(async (res) => {
        console.log("sendMFAOTP res", res)
        let mfaTableID = res.data.data.mfaTableID;
        console.log("mfaTableID", mfaTableID)
        setmfaTableID(mfaTableID);

        // await sendEmailOTP()
      })
    } catch (error) {
      console.log("axios error", error);
    }
  }

  const verifyMFAOTP = async (type = 'sms') => {
    console.log("formikOTPVerification.values.otp != ''", formikOTPVerification.values.otp != '')
    if (formikOTPVerification.values.otp.trim() != '' && formikOTPVerification.values.otp != null) {
      if (!moment().isSameOrAfter(smsOTPexpirationDate)) {
        try {
          await axios.post(process.env.REACT_APP_API_URL + '/api/verifyMFAOTP', {
            mfaTableID: mfaTableID,
            otpCode: formikOTPVerification.values.otp,
          }).then(async (res) => {
            console.log("res", res)
            console.log("res.data.result", res.data.result)
            formikOTPVerification.resetForm()

            if (res.data.result === 'success') {

              if (otpMode == 'password') {
                console.log("verifyMailOTP success")
                setIsLoading(false);
                setModalOTPVerification(false)
                handleChangePassword();
                return { success: true }
              } else if (otpMode == 'email') {
                await axios.post(process.env.REACT_APP_API_URL + '/api/addPendingChangeEmailRequest', {
                  policyNumber: POLICY_DATA,
                  newEmail: formikChangeEmail.values.email
                }).then(async (res) => {
                  console.log("addPendingChangeEmailRequest res", res)
                  if (res.data.Success == true) {
                    console.log("addPendingChangeEmailRequest res success")

                    setProfileChangeRequestType("Email")
                    setHasPendingProfileChange(true)
                    setModalChangeRequestSuccess(true)

                    setModalOTPVerification(false)
                    setIsLoading(false);
                  } else {
                    console.log("addPendingChangeEmailRequest res fail")

                    setModalOTPVerification(false)
                    setIsLoading(false);
                  }
                  setModalOTPVerification(false)
                  setIsLoading(false);
                }).catch((error) => {
                  console.log('addPendingChangeEmailRequest error', error)
                  setIsLoading(false);
                })
              } else if (otpMode == 'sms') {
                await axios.post(process.env.REACT_APP_API_URL + '/api/addPendingChangeMobileNumberRequest', {
                  policyNumber: POLICY_DATA,
                  newMobileNumber: formikChangeMobileNumber.values.mobileNumber
                }).then(async (res) => {
                  console.log("addPendingChangeMobileNumberRequest res", res)
                  if (res.data.Success == true) {
                    console.log("addPendingChangeMobileNumberRequest res success")

                    setProfileChangeRequestType("Mobile Number")
                    setHasPendingProfileChange(true)
                    setModalChangeRequestSuccess(true)

                    setModalOTPVerification(false)
                    setIsLoading(false);
                  } else {
                    console.log("addPendingChangeMobileNumberRequest res fail")

                    setModalOTPVerification(false)
                    setIsLoading(false);
                  }
                  setModalOTPVerification(false)
                  setIsLoading(false);
                }).catch((error) => {
                  console.log('addPendingChangeMobileNumberRequest error', error)
                  setModalOTPVerification(false)
                  setIsLoading(false);
                })
              }


            } else {
              // sendMFAOTP('sms')
              setModalErrorOTP(true);
              setIsLoading(false)
              return { success: false }
            }
          })
        } catch (error) {
          console.log("axios error", error);
          setIsLoading(false);
          return { success: false }
          // return {
          //   error: "There was na issue with the MFA. Please contact our customer support."
          // }
        }
      } else {
        setIsLoading(false)
        console.log("moment().isSameOrAfter(smsOTPexpirationDate)", moment().isSameOrAfter(smsOTPexpirationDate))
        setModalExpiredMobileOTP(true)
      }
    } else {
      setIsLoading(false)
      console.log("mobileotp is blank")
      formikOTPVerification.setErrors({ otp: 'Mobile OTP is required' });
    }
  }

  const sendEmailOTP = async (type = '') => {
    console.log('called sendEmailOTP');
    try {
      console.log("sendMail axios call")
      console.log("email", email)
      console.log("sendEmailOTP otpMode", otpMode)
      console.log("sendEmailOTP type 1", type)

      if (type == null || type == '') {
        if (otpMode == 'password') {
          type = 'change-password'
        } else if (otpMode == 'email') {
          type = 'change-email'
        } else if (otpMode == 'sms') {
          type = 'change-sms'
        }
      }
      console.log("sendEmailOTP type 2", type)

      await axios.post(process.env.REACT_APP_API_URL + '/api/otpGenerator', {
        email: email,
        type: type,
      }).then(async (res) => {
        console.log("sendMail res", res)
      })
    } catch (error) {
      console.log("axios error", error);
    }
  }

  const verifyMailOTP = async (type = 'sms') => {
    console.log("formikOTPVerification.values.emailOtp", formikOTPVerification.values.emailOtp != '')
    if (formikOTPVerification.values.emailOtp.trim() != '' && formikOTPVerification.values.emailOtp != null) {
      console.log("formikOTPVerification.values.emailOtp.split('').length", formikOTPVerification.values.emailOtp.split('').length)
      if (formikOTPVerification.values.emailOtp.split('').length == 6) {
        if (!moment().isSameOrAfter(emailOTPexpirationDate)) {
          try {
            console.log("sendMail axios call")
            await axios.post(process.env.REACT_APP_API_URL + '/api/otpValidate', {
              token: formikOTPVerification.values.emailOtp,
              email: email,
              type: 'change-password',
            }).then(async (res) => {
              console.log("sendMail res", res)
              formikOTPVerification.resetForm()

              if (res.data.data.Success) {
                if (otpMode == 'password') {
                  console.log("verifyMailOTP success")
                  setIsLoading(false);
                  setModalOTPVerification(false)
                  handleChangePassword();
                  return { success: true }
                } else if (otpMode == 'email') {
                  await axios.post(process.env.REACT_APP_API_URL + '/api/addPendingChangeEmailRequest', {
                    policyNumber: POLICY_DATA,
                    newEmail: formikChangeEmail.values.email
                  }).then(async (res) => {
                    console.log("addPendingChangeEmailRequest res", res)
                    if (res.data.Success == true) {
                      console.log("addPendingChangeEmailRequest res success")

                      setProfileChangeRequestType("Email")
                      setHasPendingProfileChange(true)
                      setModalChangeRequestSuccess(true)

                      setModalOTPVerification(false)
                      setIsLoading(false);
                    } else {
                      console.log("addPendingChangeEmailRequest res fail")

                      setModalOTPVerification(false)
                      setIsLoading(false);
                    }
                    setModalOTPVerification(false)
                    setIsLoading(false);
                  }).catch((error) => {
                    console.log('addPendingChangeEmailRequest error', error)
                    setIsLoading(false);
                  })
                } else if (otpMode == 'sms') {
                  await axios.post(process.env.REACT_APP_API_URL + '/api/addPendingChangeMobileNumberRequest', {
                    policyNumber: POLICY_DATA,
                    newMobileNumber: formikChangeMobileNumber.values.mobileNumber
                  }).then(async (res) => {
                    console.log("addPendingChangeMobileNumberRequest res", res)
                    if (res.data.Success == true) {
                      console.log("addPendingChangeMobileNumberRequest res success")

                      setProfileChangeRequestType("Mobile Number")
                      setHasPendingProfileChange(true)
                      setModalChangeRequestSuccess(true)

                      setModalOTPVerification(false)
                      setIsLoading(false);
                    } else {
                      console.log("addPendingChangeMobileNumberRequest res fail")

                      setModalOTPVerification(false)
                      setIsLoading(false);
                    }
                    setModalOTPVerification(false)
                    setIsLoading(false);
                  }).catch((error) => {
                    console.log('addPendingChangeMobileNumberRequest error', error)
                    setModalOTPVerification(false)
                    setIsLoading(false);
                  })
                }
              } else if (res.data.data.Error == 'Expired OTP') {
                setModalExpiredEmailOTP(true);
                setIsLoading(false)
              } else {
                setModalErrorOTP(true);
                setIsLoading(false)
              }

            })
          } catch (error) {
            console.log("axios error", error);
            setIsLoading(false);
            return { success: false }
            // return {
            //   error: "There was na issue with the MFA. Please contact our customer support."
            // }
          }
        } else {
          console.log("moment().isSameOrAfter(emailOTPexpirationDate)", moment().isSameOrAfter(emailOTPexpirationDate))
          setModalExpiredEmailOTP(true)
          setIsLoading(false)
        }
      } else {
        setModalErrorOTP(true);
        setIsLoading(false)
      }

    } else {
      setIsLoading(false)
      console.log("emailOtp is blank")
      formikOTPVerification.setErrors({ emailOtp: 'Email OTP is required' });
    }
  }

  const formikChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .min(8, 'At least 8 characters')
      .max(50, 'Too Long!')
      .required('Required'),
    newPassword: Yup.string()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .matches(/^(?=.*?[A-Z])/, 'At least one upper case letter is required')
      .matches(/^(?=.*?[a-z])/, 'At least one lower case letter is required')
      .matches(/^(?=.*?[0-9])/, 'At least one digit is required')
      .matches(/^(?=.*?[#?!@$%^&*-])/, 'At least one special character is required')
      .min(8, 'At least 8 characters')
      .max(50, 'Too Long!')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Required'),
  })

  const formikChangePassword = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    enableReinitialize: true,
    validationSchema: formikChangePasswordSchema,
    onSubmit: async values => {
      // console.log("formikChangePassword.values", formikChangePassword.values)
      setFormValues({ ...values })
      // console.log("username", username)
      // console.log("oldPassword", formikChangePassword.values.oldPassword)
      setOTPmode('password')

      await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByUsernamePassword', {
        username: username,
        password: formikChangePassword.values.oldPassword
      }).then(async (getUserByUsernamePasswordRes) => {
        console.log("getUserByUsernamePasswordRes", getUserByUsernamePasswordRes)
        if (getUserByUsernamePasswordRes.data.user == false) {
          console.log("getUserByUsernamePasswordRes fail")
          setModalWrongPassword(true)
          setIsLoading(false);
        } else {
          console.log("getUserByUsernamePasswordRes success")
          if (otpSelected === 'sms') {
            sendMFAOTP()
          } else {
            console.log("getUserByUsernamePasswordRes change-password")
            sendEmailOTP('change-password')
          }
          setModalOTPVerification(true)
        }
        setIsLoading(false);
      }).catch((error) => {
        console.log('getUserByUsernamePassword error', error)
        setIsLoading(false);
      })
    }
  })

  const handleChangePassword = async () => {
    setIsLoading(true);
    await axios.post(process.env.REACT_APP_API_URL + '/api/changePasswordByUsernamePassword', {
      username: username,
      oldPassword: formikChangePassword.values.oldPassword,
      newPassword: formikChangePassword.values.newPassword
    }).then(async (getUserByUsernamePasswordRes) => {
      console.log("getUserByUsernamePasswordRes", getUserByUsernamePasswordRes)
      if (getUserByUsernamePasswordRes.Success === false) {
        console.log("getUserByUsernamePasswordRes fail")

        setModalServerError(true)
        setIsLoading(false);
      } else {
        console.log("getUserByUsernamePasswordRes success")

        // const emailCred = firebase.auth.EmailAuthProvider.credential(
        //   firebase.auth.currentUser, formikChangePassword.values.oldPassword
        // );

        // firebase.auth.currentUser.reauthenticateWithCredential(emailCred)

        //   .then(() => {
        //     // User successfully reauthenticated.
        //     return firebase.auth.currentUser.updatePassword(formikChangePassword.values.newPassword);
        //   })
        //   .catch(error => {
        //     // Handle error.
        //   });


        await firebaseLogin(email, formikChangePassword.values.oldPassword)
          .then(() => {
            auth.currentUser.updatePassword(formikChangePassword.values.newPassword)

            let passwordHash = '';
            passwordHash = CryptoJS.SHA256(formikChangePassword.values.oldPassword).toString();
            console.log('passwordHash', passwordHash)

            // firestoreDB.collection("Table_User")
            //   .doc(auth.currentUser.uid)
            //   .update({ password: passwordHash });

            setModalSuccessfullChangePass(true)
          })
          .catch((e) => {
            console.log("error", e)
            setModalServerError(true)
          })


      }
      setIsLoading(false);
    }).catch((error) => {
      console.log('changePasswordByUsernamePassword error', error)
      setIsLoading(false);
    })
  }

  const formikOTPVerification = useFormik({
    initialValues: {
      otp: "",
      emailOtp: "",
    },
    enableReinitialize: true,
    validationSchema: regiSchemaOTP,
    onSubmit: async (values) => {
      console.log("onSubmit called")
      console.log("setIsLoading true")

      console.log("moment().isSameOrAfter(smsOTPexpirationDate)", moment().isSameOrAfter(smsOTPexpirationDate))
      if (otpSelected === 'sms') {
        verifyMFAOTP()
      } else {
        verifyMailOTP()
      }

      // console.log({ values otp != '' || })
    }
  })

  const formikChangeEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
  })

  const formikChangeEmail = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema: formikChangeEmailSchema,
    onSubmit: async values => {
      // console.log("formikChangeEmail.values", formikChangeEmail.values)
      setIsLoading(true);
      setOTPmode('email')

      await axios.post(process.env.REACT_APP_API_URL + '/api/checkIfEmailExists', {
        email: values.email,
      }).then(async (checkIfEmailExistsRes) => {
        console.log("checkIfEmailExistsRes", checkIfEmailExistsRes);
        if (checkIfEmailExistsRes.data.emailExists) {
          setIsLoading(false);
          setEmailExistsErrorModal(true)
        } else {
          setIsLoading(false);
          setotpSelected('sms')
          setSeconds(30);
          setTimerIsActive(true);
          setModalOTPVerification(true)
          setsmsOTPexpirationDate(moment().add(300, 'seconds'));
          sendMFAOTP();

        }
      })


    }
  })

  const formikChangeMobileNumberSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .min(10, 'Too Short!')
      .max(15, 'Too Long!')
      .required('Required'),
  })

  const formikChangeMobileNumber = useFormik({
    initialValues: {
      mobileNumber: '',
    },
    enableReinitialize: true,
    validationSchema: formikChangeMobileNumberSchema,
    onSubmit: async values => {
      // console.log("formikChangeMobileNumber.values", formikChangeMobileNumber.values)
      setIsLoading(true);
      setOTPmode('sms')

      await axios.post(process.env.REACT_APP_API_URL + '/api/checkIfMobileExists', {
        mobile: values.mobileNumber,
      }).then(async (checkIfMobileExistsRes) => {
        console.log("checkIfMobileExistsRes", checkIfMobileExistsRes);
        if (checkIfMobileExistsRes.data.mobileExists) {
          setIsLoading(false);
          setMobileNumberExistsErrorModal(true)
        } else {
          setIsLoading(false);
          setotpSelected('email')
          setSeconds2(30);
          setTimerIsActive2(true);
          setModalOTPVerification(true)
          setEmailOTPexpirationDate(moment().add(300, 'seconds'));
          sendEmailOTP('change-sms');

        }
      })


    }
  })

  return (
    <div>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOTPVerification}
        onClose={() => {
          setModalOTPVerification(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOTPVerification}>
          <Box sx={style}>

            <FormControl sx={{ display: "flex", lexDirection: "column", alignItems: "center", textAlign: 'center' }}>
              <form onSubmit={formikOTPVerification.handleSubmit} name='formikOTPVerification' key='formikOTPVerification'>

                <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
                  Change {otpMode == 'password' ? 'Password' : otpMode == 'email' ? 'Email' : 'Mobile Number'} OTP Verification
                </Typography>


                <Divider sx={{ my: 3, width: '100%' }} />


                {otpSelected === 'sms' ?
                  <>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Enter the OTP Code that we have sent to your Mobile Number
                      </Typography>

                      <TextField
                        name="otp"
                        variant="outlined"
                        hiddenLabel
                        value={formikOTPVerification.values.otp}
                        placeholder='Mobile OTP Code'
                        onChange={formikOTPVerification.handleChange}
                        sx={{ minWidth: 80, maxWidth: '100%' }}
                      />
                      {formikOTPVerification.errors.otp ? <Typography color="red">{formikOTPVerification.errors.otp}</Typography> : null}
                    </Stack>


                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mt: 1 }}
                    >
                      {timerIsActive ?
                        <Typography variant="subtitle1" sx={{ mt: 1, textAlign: 'center' }}>
                          Resend a new OTP code in {seconds} seconds
                        </Typography>
                        :
                        <Button variant="text" size="large"
                          onClick={() => {
                            setSeconds(30);
                            setTimerIsActive(true);
                            setsmsOTPexpirationDate(moment().add(300, 'seconds'));
                            sendMFAOTP();
                          }}>
                          Resend a new OTP Code to mobile
                        </Button>}
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="body1" sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Mobile OTP Expiry: {smsOTPexpirationDate.format('MMM DD, YYYY hh:mm:ss a')}
                      </Typography>
                    </Stack>
                  </>
                  :
                  <>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Enter the OTP Code that we have sent to your Email Address
                      </Typography>

                      <TextField
                        name="emailOtp"
                        variant="outlined"
                        hiddenLabel
                        value={formikOTPVerification.values.emailOtp}
                        placeholder='Email OTP Code'
                        onChange={formikOTPVerification.handleChange}
                        sx={{ minWidth: 80, maxWidth: '100%' }}
                      />
                      {formikOTPVerification.errors.emailOtp ? <Typography color="red">{formikOTPVerification.errors.emailOtp}</Typography> : null}
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mt: 1 }}
                    >
                      {timerIsActive2 ?
                        <Typography variant="subtitle1" sx={{ mt: 1, textAlign: 'center' }}>
                          Resend a new OTP code in {seconds2} seconds
                        </Typography>
                        :
                        <Button variant="text" size="large"
                          onClick={() => {
                            setSeconds2(30);
                            setTimerIsActive2(true);
                            setEmailOTPexpirationDate(moment().add(300, 'seconds'));
                            sendEmailOTP();
                          }}>
                          Resend a new OTP Code to email
                        </Button>}
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="body1" sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                        Email OTP Expiry: {emailOTPexpirationDate.format('MMM DD, YYYY hh:mm:ss a')}
                      </Typography>
                    </Stack>
                  </>}

                <Divider sx={{ my: 1, width: '100%' }} />
                {otpMode == 'password' ?
                  <>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                      sx={{ mt: 5, width: '100%' }}
                    >
                      {otpSelected === 'sms' ?
                        <>
                          {timerIsActive2 ?
                            <>
                              <Button variant="text" size="large"
                                disabled>
                                Use Email OTP {seconds2} Seconds
                              </Button>
                            </>
                            :
                            <>
                              <Button variant="text" size="large"
                                onClick={() => {
                                  setotpSelected('email')
                                  setSeconds2(30);
                                  setTimerIsActive2(true);
                                  setEmailOTPexpirationDate(moment().add(300, 'seconds'));
                                  sendEmailOTP();
                                }}>
                                Use Email OTP
                              </Button>
                            </>
                          }
                        </>
                        :
                        <>
                          {timerIsActive ?
                            <>
                              <Button variant="text" size="large"
                                disabled>
                                Use Mobile in {seconds} Seconds
                              </Button>
                            </>
                            :
                            <>
                              <Button variant="text" size="large"
                                onClick={() => {
                                  setotpSelected('sms')
                                  setSeconds(30);
                                  setTimerIsActive(true);
                                  setsmsOTPexpirationDate(moment().add(300, 'seconds'));
                                  sendMFAOTP();
                                }}>
                                Use Mobile OTP
                              </Button>
                            </>
                          }
                        </>
                      }
                      <Button type="submit" variant="contained" size="large">
                        Submit
                      </Button>
                    </Stack>
                  </>
                  :
                  <>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={3}
                      sx={{ mt: 5, width: '100%' }}
                    >
                      <Button type="submit" variant="contained" size="large">
                        Submit
                      </Button>
                    </Stack>
                  </>}

              </form>
            </FormControl>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalChangeRequestSuccess}
        onClose={() => {
          setModalChangeRequestSuccess(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalChangeRequestSuccess}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {profileChangeRequestType} change request sent!
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalChangeRequestSuccess(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalExpiredMobileOTP}
        onClose={() => {
          setModalExpiredMobileOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalExpiredMobileOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The Mobile OTP you have entered has expired.
        Click Resend code to receive a new Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalExpiredMobileOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalExpiredEmailOTP}
        onClose={() => {
          setModalExpiredEmailOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalExpiredEmailOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The Email OTP you have entered has expired.
  Click Resend code to receive a new Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalExpiredEmailOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalErrorOTP}
        onClose={() => {
          setModalErrorOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalErrorOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The OTP you have entered is incorrect.
              Please enter the correct OTP Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalErrorOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalSuccessfullChangePass}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalSuccessfullChangePass(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalSuccessfullChangePass}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`User has successfully changed password!`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalSuccessfullChangePass(false)
                setIsLoading(true);
                window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalWrongPassword}
        onClose={() => {
          setModalWrongPassword(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalWrongPassword}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              You old password is incorrect.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Please enter the correct old password.
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalWrongPassword(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalServerError}
        onClose={() => {
          setModalServerError(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalServerError}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Server Error
            </Typography>

            <Divider sx={{ my: 3, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              There was an issue with the server.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Please try again.
            </Typography>

            <Button variant="contained"
              onClick={() => {
                setModalServerError(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={mobileNumberExistsErrorModalOpen}
        onClose={() => {
          setMobileNumberExistsErrorModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={mobileNumberExistsErrorModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Mobile Number already taken
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Box sx={{ mx: 1 }}>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                The Mobile Number you have entered has already been used.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  setMobileNumberExistsErrorModal(false)
                }}>
                Ok
              </Button>
            </Box>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={emailExistsErrorModalOpen}
        onClose={() => {
          setEmailExistsErrorModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={emailExistsErrorModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Email already taken
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Box sx={{ mx: 1 }}>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                The Email you have entered has already been taken.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  setEmailExistsErrorModal(false)
                }}>
                Ok
              </Button>
            </Box>

          </Box>
        </Fade>
      </Modal>












      <Typography variant="h4" color="text.primary" sx={{ mb: 5 }}>
        My Profile Account{" "}
      </Typography>

      <APILoadingOverlay customlabel={loadingCustomLabel}
        open={isLoading} setOnParent={(value) => setIsLoading(value)}>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Personal Information</Typography>
          {/* <Button variant="text" color="secondary">Edit</Button> */}

        </Stack>

        <Divider sx={{ mt: 1 }} />
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Stack>
              <Typography color="text.primary" variant="overline">
                First Name
              </Typography>

              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                hiddenLabel
                value={firstName}
                disabled
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Stack>
              <Typography color="text" variant="overline">
                Middle Name
              </Typography>

              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                hiddenLabel
                value={middleName}
                disabled
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Stack>
              <Typography color="text" variant="overline">
                Last Name
              </Typography>

              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                hiddenLabel
                value={lastName}
                disabled
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Stack>
              <Typography color="text" variant="overline">
                Suffix
              </Typography>

              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                hiddenLabel
                value={suffix}
                disabled
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Stack>
              <Typography color="text" variant="overline">
                Username{" "}
              </Typography>

              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                hiddenLabel
                value={username}
                disabled
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Stack>
              <Typography color="text" variant="overline">
                Email Address{" "}
              </Typography>

              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                hiddenLabel
                value={email}
                disabled
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Stack>
              <Typography color="text" variant="overline">
                Mobile number{" "}
              </Typography>

              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                hiddenLabel
                value={mobileNumber}
                disabled
              />
            </Stack>
          </Grid>

          {/* <Grid item xs={12} sx={{ mt: 3 }}>
            <Stack spacing={2} direction="row">
              <Button variant="contained" size="large">
                Update Information{" "}
              </Button>
              <Button variant="text" size="large">
                Cancel
              </Button>
            </Stack>
          </Grid>*/}

        </Grid>

        {/* <Typography
          variant="h6"
          sx={{ marginBottom: "10px", marginTop: "100px" }}
        >
          Update Personal Information
        </Typography>

        <Divider sx={{ mt: 1 }} />

        {!hasPendingProfileChange ?
          <>
            <Grid container spacing={0} sx={{ marginTop: "10px" }}>

              <Grid item xs={12} sm={9} md={8} lg={6}>
                <FormControl sx={{ mb: 1 }} fullWidth>
                  <form onSubmit={formikChangeEmail.handleSubmit} name='formikChangeEmail' key='formikChangeEmail'>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Stack sx={{ marginTop: "" }}>
                          <Typography color="text" variant="overline">
                            New Email
                          </Typography>

                          <TextField
                            name="email"
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            hiddenLabel
                            value={formikChangeEmail.values.email}
                            onChange={formikChangeEmail.handleChange}
                          />
                          {formikChangeEmail.errors.email ?
                            <Typography color="red">{formikChangeEmail.errors.email}</Typography>
                            : null}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 0, mb: 1 }}>
                        <Stack spacing={2} direction="row">
                          <Button type="submit" variant="contained" size="large">
                            Update Email{" "}
                          </Button>
                        </Stack>
                      </Grid>

                    </Grid>
                  </form>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={9} md={8} lg={6}>
                <FormControl sx={{ mb: 0 }} fullWidth>
                  <form onSubmit={formikChangeMobileNumber.handleSubmit} name='formikChangeMobileNumber' key='formikChangeMobileNumber'>
                    <Grid container spacing={2} sx={{ mt: 0 }}>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Stack sx={{ marginTop: "" }}>
                          <Typography color="text" variant="overline">
                            New Mobile Number
                          </Typography>

                          <TextField
                            name="mobileNumber"
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            hiddenLabel
                            value={formikChangeMobileNumber.values.mobileNumber}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                formikChangeMobileNumber.handleChange(e)
                              }
                            }}
                          />
                          {formikChangeMobileNumber.errors.mobileNumber ?
                            <Typography color="red">{formikChangeMobileNumber.errors.mobileNumber}</Typography>
                            : null}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 1, mb: 0 }}>
                        <Stack spacing={2} direction="row">
                          <Button type="submit" variant="contained" size="large">
                            Update Mobile Number{" "}
                          </Button>
                        </Stack>
                      </Grid>

                    </Grid>
                  </form>
                </FormControl>
              </Grid>

            </Grid>
          </>
          :
          <>
            <Typography variant="body2" sx={{ mt: 2 }}>
              User currently has a pending {profileChangeRequestType} change request.
            </Typography>
          </>} */}

        {hasChangePassword ?
          <>
            <Typography
              variant="h6"
              sx={{ marginBottom: "10px", marginTop: "100px" }}
            >
              Change Password
            </Typography>

            <Divider sx={{ mt: 1 }} />

            <FormControl sx={{ my: 1 }} fullWidth>
              <form onSubmit={formikChangePassword.handleSubmit} name='formikChangePassword' key='formikChangePassword'>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={4}>
                    <Stack>
                      <Typography color="text" variant="overline">
                        Old Password
                      </Typography>

                      <TextField
                        name="oldPassword"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        value={formikChangePassword.values.oldPassword}
                        onChange={formikChangePassword.handleChange}
                        type="password"
                      />
                      {formikChangePassword.errors.oldPassword ?
                        <Typography color="red">{formikChangePassword.errors.oldPassword}</Typography>
                        : null}
                    </Stack>
                    <Stack sx={{ marginTop: "20px" }}>
                      <Typography color="text" variant="overline">
                        New Password
                      </Typography>

                      <TextField
                        name="newPassword"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        value={formikChangePassword.values.newPassword}
                        onChange={formikChangePassword.handleChange}
                        type="password"
                      />
                      {formikChangePassword.errors.newPassword ?
                        <Typography color="red">{formikChangePassword.errors.newPassword}</Typography>
                        : null}
                    </Stack>

                    <Stack sx={{ marginTop: "20px" }}>
                      <Typography color="text" variant="overline">
                        Confirm New Password
                      </Typography>

                      <TextField
                        name="confirmPassword"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        value={formikChangePassword.values.confirmPassword}
                        onChange={formikChangePassword.handleChange}
                        type="password"
                      />
                      {formikChangePassword.errors.confirmPassword ?
                        <Typography color="red">{formikChangePassword.errors.confirmPassword}</Typography>
                        : null}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
                    <Stack spacing={2} direction="row">
                      <Button type="submit" variant="contained" size="large">
                        Update Password{" "}
                      </Button>
                    </Stack>

                    {/* <Box sx={{ mt: 2 }}>
                      <Typography variant="caption">
                        Forgot your{" "}
                        <Link href="#" sx={{ textDecoration: "none" }} color="secondary">
                          password
                        </Link>
                        ?
                      </Typography>
                    </Box> */}
                  </Grid>
                </Grid>
              </form>
            </FormControl>
          </>
          : null}


      </APILoadingOverlay>
    </div >
  );
}
