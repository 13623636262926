import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box, Typography, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Divider, Grid
} from "@mui/material";
import moment from 'moment-timezone';
import MaterialUITable from "../components/MaterialUITable";

import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";

import APILoadingOverlay from "../components/APILoadingOverlay";
import APIErrorModal from "../components/APIErrorModal";
import axios from 'axios';

export default function Others(props) {
  let dispatch = useDispatch();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  // const USERNAME = useSelector((state) => state.username.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)
  const [policyAgent, setPolicyAgent] = useState({});

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    async function init() {
      let policyAgentReqData = {
        // policyNumber: 'T0095469',
        policyNumber: POLICY_DATA,
      }

      await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyAgent', policyAgentReqData)
        .then(async (res) => {
          console.log("getPolicyAgent res.data.Data", res.data.Data);

          if (res === 'null') {
            setapiName('getPolicyAgent')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPolicyAgent res is null')
            return
          }

          if (res.data.Success) {
            setPolicyAgent(res.data.Data);
          } else {
            setapiName('getPolicyAgent')
            setapiResponse(res.data)
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPolicyAgent res', res.data.Response)
            return
          }

          setIsLoading(false)
        })

      // let policyAgent = {
      //   data: {
      //     "getPolicyAgentResult": {
      //       "Data": {
      //         "ASDName": "XXXXXXXX, XXXXX XXXXX",
      //         "AgencyName": "X. XXXXXXXX XXXXXX",
      //         "AgentName": "XXXXXXX, XXXXXXXX XXXXXXXX",
      //         "EffectiveDate": "11/6/2018 8:06:17 AM",
      //         "USDName": "XXXXXXX, XXXXXXXX XXXXXXXX"
      //       },
      //       "RequestedOn": "1/27/2022 3:53:16 AM",
      //       "Response": {
      //         "Code": "200",
      //         "Message": "Successful."
      //       },
      //       "Success": true
      //     }
      //   }
      // }

      // setPolicyAgent(policyAgent.data.getPolicyAgentResult.Data);
    }
    init()
  }, [])

  return (
    <Box>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

      <Typography variant="h4" color="text.primary" sx={{ mb: 5 }}>
        Others{" "}
      </Typography>

      <APILoadingOverlay customlabel={loadingCustomLabel}
        open={isLoading} setOnParent={(value) => setIsLoading(value)}>
        {/* <Typography variant="h6">Policy Agents</Typography>
      <Typography variant="body1" color="text.secondary">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </Typography> */}

        <Box sx={{ mt: 5 }}>
          <Typography variant="h6" >Servicing Unit</Typography>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" color="text.subtitle2">
                Agent Name
              </Typography>
              <Typography variant="h6" color="text.primary">
                {policyAgent.AgentName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" color="text.subtitle2">
                Agency Name
              </Typography>
              <Typography variant="h6" color="text.primary">
                {policyAgent.AgencyName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" color="text.subtitle2">
                Agency Sales Director Name
              </Typography>
              <Typography variant="h6" color="text.primary">
                {policyAgent.ASDName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" color="text.subtitle2">
                Unit Sales Director Name
              </Typography>
              <Typography variant="h6" color="text.primary">
                {policyAgent.USDName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" color="text.subtitle2">
                Effective Date
              </Typography>
              <Typography variant="h6" color="text.primary">
                {moment(policyAgent.EffectiveDate, "MM/DD/YYYY hh:mm:ss a").isValid() ? moment(policyAgent.EffectiveDate, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY') : ''}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* <Box sx={{ mt: 8 }}>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>Policy Agent History / Transfer of Business</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ fontWeight: "600" }}>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  Agent Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  USD Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  ASD Name                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  Effective Date                </TableCell>

                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  Post Date
                </TableCell>

                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  UserID                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agentList.map((row) => (
                <TableRow
                  hover
                  key={row.userID}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {row.agent}
                  </TableCell>
                  <TableCell align="center">{row.usd}</TableCell>
                  <TableCell align="center">{row.asd}</TableCell>
                  <TableCell align="center">{row.eff}</TableCell>
                  <TableCell align="center">{row.post}</TableCell>
                  <TableCell align="center">{row.userID}</TableCell>
                </TableRow>
              ))}
              <TableRow />
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
      </APILoadingOverlay>
    </Box>
  );
}
